import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    // flexDirection: "column",
    // backgroundColor: 'lime',
    // borderColor: 'black',
    // borderWidth: 2,
    // width: "60%",
    // height: "60%",
    // justifyContent: "flex-start",
    justifyContent: "space-evenly", // "center", //space-between
    alignItems: "center",
  },
  language_item: {
    margin: 20,
    // width:"100%",
    // backgroundColor: "lightblue",

  },
  
  image: {
    width: 100,
    height: 100,
  },
  text: {
    textAlign: "center",
    fontSize: 20,
    marginTop: 5,
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    margin: 5,
  },
  button: {
    // margin: 10,
    backgroundColor: "lightblue",
    marginBottom: 20,
    padding: 10,
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 2,
  },
});
