import React, { useCallback, useContext, useEffect } from "react";
import { Pressable, View, Text } from "react-native";

import { Grid7 } from "../../components/Grids";
import { styles } from "./DailyGrid.styles";
import { GridType } from "../../api/types/GridsTypes";
import GlobalParams from "../../components/Globals/GlobalParams";
import PredefinedWords from "./PredefinedWords";
import { useFocusEffect } from "@react-navigation/native";
import i18n from "../../Lang/translations";
import { GridItem } from "../../components/Grids/Core/GridItem/GridItem";
import { UserContext, userContext } from "../../Context/userContext";

interface selectedWord {
  index: number;
  name: string;
  data: GridItem[];
}

export default function DailyGrid() {
  const user = useContext<userContext>(UserContext);

  function randomGrid(): number {
    return Math.floor(Math.random() * PredefinedWords.length);
  }

  const randomWord = () => {
    const newIndex = randomGrid();
    const newSelected: selectedWord = {
      index: newIndex,
      name: PredefinedWords[newIndex].name,
      data: PredefinedWords[newIndex].data.map((x: number, i: number) => {
        return { Shape: x, Color: 7 - i } as GridItem;
      }),
    };
    return newSelected;
  };

  const [selectedWord, setSelectedWord] = React.useState<selectedWord>(
    randomWord()
  );

  useFocusEffect(
    useCallback(() => {
      // rotate the grid when the screen is focused
      setSelectedWord(randomWord());
    }, [])
  );

  return (
    <View>
      <Pressable
        style={styles.gridnameheader}
        onPress={() => {
          setSelectedWord(randomWord());
        }}
      >
        {/* <Text>{i18n.t("screens.home.daily_grid.grid")}</Text> */}
        <Text style={styles.text}>
          {i18n.t("predefined_words." + selectedWord.name.toLowerCase())}
        </Text>
      </Pressable>
      <Grid7
        data={selectedWord.data}
        level={GridType.G49}
        readonly={true}
        setNewData={(newData: GridItem[]) => {}}
      ></Grid7>
    </View>
  );
}
