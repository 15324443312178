// the login request is being send in two cases:
// 1. on login - uses: email and password
// 2. on relogin - uses: user id

enum AccountErrors {
  OK = 0,
  NotFound = -1,
  MissingInputData = -2,
  UserAlreadyexists = -3,
}

// react-hooks-global-state
interface LoginRequest {
  Email?: string;
  Password?: string;
  UserId?: string;
  Platform?: string;
  Device?: string;
  Version?: string;
  AppVersion?: string;
}

interface LoginResponse {
  Status: AccountErrors;
  Id: string;
  Name: string;
  Upgrade: boolean;
  UpgradeURL: string;
}

interface RegisterRequest {
  Name: string;
  Teacher: string;
  Email: string; // email is the username
  Password: string;
  Platform?: string; // Web, IOS, Android
  Device?: string; // Phone, Table, TV
  Version?: string; // iphone 12, iphone 13,
  AppVersion?: string;
}

interface IUser {
  Id: string;
  TeacherId: string;
  Email: string;
  UserName: string;
  Teacher: string;
  Created: string; //Date;
  Deleted: boolean;
  
}

export { AccountErrors, LoginRequest, LoginResponse, RegisterRequest, IUser };
