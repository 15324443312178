import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    // flex: 1,
    alignSelf: "stretch",
    // justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "lightblue",
    backgroundColor: "lightgreen",
  },
  text: {
    // flex: 1,
    alignSelf: "center", // "flex-start",
    fontSize: 20,
    fontWeight: "bold",
    backgroundColor: "lightbrown",
    margin: 10,
    marginHorizontal: 40,
  },
  gridnameheader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "lightblue",
  },
  image: {
    width: 72,
    height: 72,
    marginTop: 20,
  },
  imageIcon: {
    width: 24,
    height: 24,
    marginVertical: 6,
  },
});
