import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";

import { styles } from "./StoresLogo.styles";
import i18n from "../../Lang/translations";
import { useContext, useState } from "react";
import { UserContext, userContext } from "../../Context/userContext";
import GlobalParams from "../../components/Globals/GlobalParams";
import AwesomeAlert from "react-native-awesome-alerts";

const buttonItems = [
  {
    pltform: "android",
    url: "https://play.google.com/store/apps/details?id=com.Light.Language",
    imageId: {
      source: require("../../../assets/images/store/android-play.png"),
    },
  },
  {
    pltform: "ios",
    url: "https://apps.apple.com/il/app/light-language/id1671357144",
    imageId: { source: require("../../../assets/images/store/ios-store.png") },
  },
  {
    pltform: "web",
    url: "https://www.lightlanguagegrid.com/",
    imageId: { source: require("../../../assets/images/store/browser.png") },
  },
];

const StoresLogo = () => {
  const [showIOSDialog, setIOSDialog] = useState(false);
  const user = useContext<userContext>(UserContext);

  const navigateOutside = async (url: string) => {
    if (url === null || url === undefined || url === "") {
      url = GlobalParams.GetIOSLink();
    }
    // in app browser
    // let result = await WebBrowser.openBrowserAsync(url);

    if (url && url.search(":") > 0) {
      /// outside app browser - system browser
      Linking.openURL(url);
      return;
    }

    // else
    // display the message
    setIOSDialog(true);
  };

  const renderLogo = (item: any, i: number) => {
    return (
      ((Platform.OS === "web" && item.pltform !== "web") ||
        (Platform.OS !== "web" && item.pltform === "web")) && (
        <TouchableOpacity
          key={i.toString()}
          onPress={() => navigateOutside(item.url)}
        >
          <Image style={styles.image} {...item.imageId} />
        </TouchableOpacity>
      )
    );
  };

  const iosDialog = () => {
    return (
      <AwesomeAlert
        show={showIOSDialog}
        showProgress={false}
        title={i18n.t("screens.home.store_logo.ios_title")}
        message={i18n.t("screens.home.store_logo.ios_message")}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText={i18n.t("screens.home.store_logo.ios_ok")}
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setIOSDialog(false);
        }}
        onDismiss={() => {
          setIOSDialog(false);
        }}
        onConfirmPressed={() => {
          setIOSDialog(false);
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {i18n.t("screens.home.store_logo.available")}
      </Text>
      <View style={styles.viewimagetext}>
        {buttonItems.map((item, i) => renderLogo(item, i))}
      </View>
      {iosDialog()}
    </View>
  );
};

export default StoresLogo;
