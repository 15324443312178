import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { View, Text, TextInput, BackHandler } from "react-native";
import Grids from "../api/services/Grids";
import GridService from "../api/services/Grids";
import { GridData } from "../api/types/GridData";
import { GridType } from "../api/types/GridsTypes";
import GlobalParams from "../components/Globals/GlobalParams";
import Logs from "../components/Globals/Logs";
import { Grid144, Grid49, Grid7 } from "../components/Grids";
import { GridItem } from "../components/Grids/Core/GridItem/GridItem";
import { UserContext, userContext } from "../Context/userContext";
import i18n from "../Lang/translations";
import { RootStackNavigationProp } from "../Navigation/types";
import { styles } from "./GridScreen.styles";

const GridScreen = () => {
  const navigation = useNavigation<RootStackNavigationProp>();

  const user = useContext<userContext>(UserContext);
  const [gridData, setGridData] = useState<GridData>(); // route?.params;
  const [isReadonly, setReadonly] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  const InitGridScreen = () => {
    // everything starts here
    if (Logs.ScreenGridLogs) console.log("InitGridScreen [current]:", gridData);

    if (user.ViewOnlyByExternalId) {
      if (Logs.ScreenGridLogs)
        console.log("InitGridScreen [ExternalId]:", user.ViewOnlyByExternalId);
      setReadonly(true);
      loadGridDataByExternalid(user.ViewOnlyByExternalId);
      // readonly mode
    } else {
      if (Logs.ScreenGridLogs)
        console.log("InitGridScreen [new]:", user.ActiveGrid);

      const grid = user.ActiveGrid;
      if (grid !== undefined) {
        if (grid.Id === undefined || grid.Id === 0) {
          // new grid
          if (grid.Title === null) {
            grid.Title = "";
          }
          if (grid.Name === null) {
            grid.Name = "";
          }

          setGridData(user.ActiveGrid);
        } else {
          // Load the grid data from the server
          //
          if (user.ActiveGrid.Id !== undefined && user.ActiveGrid.Id !== 0) {
            loadGridData(user.Id!, user.ActiveGrid.Id);
          }
        }
      }
    }
  };

  const loadGridDataByExternalid = (externalId: string) => {
    if (Logs.ScreenGridLogs)
      console.log("loadGridData [externalId]:", externalId);
    const grid = GridService.LoadByExternalId(externalId);
    handleLoadResponse(grid);
  };

  const loadGridData = (user: string, id: number) => {
    if (Logs.ScreenGridLogs) console.log("loadGridData:", user, id);
    const grid = GridService.Load(user, id);
    handleLoadResponse(grid);
  };
  const handleLoadResponse = (grid: Promise<GridData>) => {
    grid.then((grid) => {
      setGridData(grid);
    });
  };

  useEffect(() => {
    if (!isDataChanged) {
      return;
    }
    if (Logs.ScreenGridLogs) console.log("gridData - changed: ", gridData);
    saveGrid(gridData!);
    setDataChanged(false);
  }, [isDataChanged]);

  useEffect(() => {
    // this method is being called before the useFocusEffect with the correct value
    // move the logic to here.
    // not sure why is this heppening!!!
    if (user.ActiveGrid) {
      // console.log(
      //   "openGrid - before init:",
      //   user.ActiveGrid?.Id,
      //   user.ActiveGrid?.Name
      // );

      InitGridScreen();
    }
  }, [user.ActiveGrid]);

  useFocusEffect(
    useCallback(() => {
      // trigger from here - the use case of external id
      // as we do not set the user.ActiveGrid
      if (user.ViewOnlyByExternalId) {
        InitGridScreen();
      }

      // the part below is generic - I just copy and paste if from
      // https://www.programcreek.com/typescript/?api=@react-navigation/native.useFocusEffect
      // more info : https://reactnavigation.org/docs/navigation-lifecycle/

      const onBackPress = (): boolean => {
        navigation.navigate("Home");
        return true;
      };
      BackHandler.addEventListener("hardwareBackPress", onBackPress);
      return (): void =>
        BackHandler.removeEventListener("hardwareBackPress", onBackPress);
    }, [navigation])
  );

  const saveGrid = useRef(
    debounce(async (gridData: GridData) => {
      // logic
      if (Logs.ScreenGridLogs) console.log("XXX:[SAVE]:", gridData);
      const savedGridData = Grids.Save(user.Id!, gridData);
      savedGridData.then((data) => {
        if (gridData.Id == 0) {
          // save new intance on first save
          setGridData(data);
        }
      });
    }, 2000)
  ).current;

  const gridHeader = () => {
    return !isReadonly ? gridHeaderEdit() : gridHeaderReadonly();
  };

  const gridHeaderReadonly = () => {
    return (
      <View style={styles.header}>
        <View style={styles.textLine}>
          <Text style={styles.textLineText}>{gridData?.Title} </Text>
        </View>
      </View>
    );
  };
  const gridHeaderEdit = () => {
    if (!gridData || gridData.GridType === GridType.G144)
      return <View style={styles.main}></View>;

    return (
      <View style={styles.header}>
        <View style={styles.textLine}>
          {/* <Text style={styles.textLineText}>{i18n.t("screens.grid.name")}</Text> */}
          <TextInput
            style={styles.textLineInput}
            onChangeText={(name) => {
              setGridData({ ...gridData, Name: name });
              setDataChanged(true);
            }}
            value={gridData.Name}
            placeholder={i18n.t("screens.grid.name")}
          />
        </View>

        <View style={styles.textLine}>
          {/* <Text style={styles.textLineText}>
            {i18n.t("screens.grid.title")}
          </Text> */}
          <TextInput
            style={styles.textLineInput}
            onChangeText={(title) => {
              setGridData({ ...gridData, Title: title });
              setDataChanged(true);
            }}
            value={gridData.Title}
            placeholder={i18n.t("screens.grid.title")}
          />
        </View>
      </View>
    );
  };

  const setData = (data: GridItem[], save: boolean) => {
    if (!gridData) return;
    setGridData({ ...gridData, Data: data });
    if (save) {
      setDataChanged(true);
    }
  };

  const mainGridArea = () => {
    if (!gridData) return <View style={styles.main}></View>;
    if (gridData.Data.length === 0)
      return (
        <View style={styles.main}>
          <Text>{i18n.t("screens.grid.internal_error")}</Text>
        </View>
      );

    return (
      <View style={styles.main}>
        {gridData.GridType === GridType.G7 && ( //gridData.Data.length == 7 && (
          <Grid7
            data={gridData.Data}
            setNewData={setData}
            level={GridType.G7}
            readonly={isReadonly || false}
          />
        )}
        {gridData.GridType === GridType.G7_PLUS && ( //gridData.Data.length == 7 && (
          <Grid7
            data={gridData.Data}
            setNewData={setData}
            level={GridType.G49}
            readonly={isReadonly || false}
          />
        )}
        {gridData.GridType === GridType.G49 && ( //gridData.Data.length == 49 && (
          <Grid49
            data={gridData.Data}
            setNewData={setData}
            level={GridType.G49}
            readonly={isReadonly || false}
          />
        )}
        {gridData.GridType === GridType.G144 && ( //gridData.Data.length == 144 && (
          <Grid144
            data={gridData.Data}
            setNewData={setData}
            level={GridType.G49}
            readonly={isReadonly || false}
          />
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {!gridData ? (
        <Text>{i18n.t("screens.grid.loading")}</Text>
      ) : gridData.GridType === GridType.G144 ? (
        <View></View>
      ) : (
        gridHeader()
      )}
      {mainGridArea()}
    </View>
  );
};

export default GridScreen;
