import axios from "axios";
import GlobalParams from "../../components/Globals/GlobalParams";

const apiClient = axios.create({
  baseURL: GlobalParams.GetServerAPIURL(),
  headers: {
    "Content-type": "application/json",
  },
});

export default apiClient;


/*

https://www.bezkoder.com/react-query-axios-typescript/


 const { isLoading, isSuccess, isError, data, error, refetch } =
                 useQuery<Tutorial[], Error>('query-tutorials', fetchTutorials, { enabled: false, retry: 2, onSuccess, onError });


['query-tutorials', title].




/*
Now we can use apiClient to send HTTP requests and receive responses.

The response for a Axios request contains:

data: parsed response body provided by the server
status: HTTP status code
statusText: HTTP status message
headers: HTTP headers (lower case)
config: the request config that was provided to axios
request: the last client request instance that generated this response


const findAll = async () => {
  const response = await apiClient.get<Tutorial[]>("/tutorials");
  return response.data;
}

const findById = async (id: any) => {
  const response = await apiClient.get<Tutorial>(`/tutorials/${id}`);
  return response.data;
}

const findByTitle = async (title: string) => {
  const response = await apiClient.get<Tutorial[]>(`/tutorials?title=${title}`);
  return response.data;
}

const create = async ({ title, description }: Tutorial) => {
  const response = await apiClient.post<any>("/tutorials", { title, description });
  return response.data;
}

const update = async (id: any, { title, description, published }: Tutorial) => {
  const response = await apiClient.put<any>(`/tutorials/${id}`, { title, description, published });
  return response.data;
}

const deleteById = async (id: any) => {
  const response = await apiClient.delete<any>(`/tutorials/${id}`);
  return response.data;
}

const deleteAll = async () => {
  const response = await apiClient.delete<any>("/tutorials");
  return response.data;
}

const TutorialService = {
  findAll,
  findById,
  findByTitle,
  create,
  update,
  deleteById,
  deleteAll
}

export default TutorialService;

*/
/*

import http from "../http-common";
import ITutorialData from "../types/Tutorial";
import GlobalParams from './../../components/Globals/GlobalParams';

const getAll = () => {
  return http.get<Array<ITutorialData>>("/tutorials");
};

const get = (id: any) => {
  return http.get<ITutorialData>(`/tutorials/${id}`);
};

const create = (data: ITutorialData) => {
  return http.post<ITutorialData>("/tutorials", data);
};

const update = (id: any, data: ITutorialData) => {
  return http.put<any>(`/tutorials/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/tutorials/${id}`);
};

*/
