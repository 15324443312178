import React from "react";
import { View } from "react-native";
import { GridItem } from "../Core/GridItem/GridItem";
import ShapeColor from "../Core/ShapeColor/ShapeColor";
import { styles } from "./GridRow.styles";

export interface GridRowProps {
  data: GridItem[];
  rowId: number;

  // notify parent on the new data that was changed / selected.
  // setNewData: (newData: GridItem[]) => void;
  onClick: (id: number) => void;
  column: number /* 7 - grid 49, 12 dor grid144 - need to be selected from 'enum' list  */;
  readonly: boolean;
}

const GridRow = (props: GridRowProps) => {
  return (
    <View style={styles.gridRow}>
      {[...Array(/*7*/ props.column)].map((x, i) => (
        // <View>
        //   <Text>{props.rowId + " - " + i}</Text>
        <ShapeColor
          key={i}
          onClick={() => props.onClick(props.rowId * /*7*/ props.column + i)}
          gridItem={props.data[props.rowId * /*7*/ props.column + i]}
          readonly={props.readonly}
        />
        // </View>
      ))}
    </View>
  );
};

export default GridRow;
