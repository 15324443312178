import i18n from "../../Lang/translations";
import { GridItem } from "../../components/Grids/Core/GridItem/GridItem";
import { WordGridItem } from "../../components/Grids/Core/PredefineShapes/PredefineShapes";

export interface Grid7Word {
  name: string;
  data: number[];
}
const PredefinedWords: Grid7Word[] = [
  { name: "Ability", data: [3, 2, 2, 3, 2, 3, 2] },
  { name: "Abundance", data: [4, 4, 4, 2, 1, 2, 1] },
  { name: "Admiration", data: [7, 6, 6, 2, 6, 6, 6] },
  { name: "Adoration", data: [1, 1, 2, 6, 6, 6, 2] },
  { name: "Affection", data: [1, 7, 1, 5, 2, 5, 2] },
  { name: "Allow", data: [4, 4, 4, 4, 4, 4, 4] },
  { name: "Amazement", data: [7, 6, 7, 7, 7, 7, 1] },

  { name: "Appreciation", data: [1, 1, 6, 2, 2, 2, 2] },
  { name: "Balance", data: [7, 7, 7, 2, 1, 1, 1] },
  { name: "Beauty", data: [3, 3, 3, 2, 3, 2, 1] },
  { name: "Blessing", data: [7, 5, 5, 5, 6, 6, 1] },
  { name: "Bravery", data: [1, 6, 1, 1, 2, 1, 2] },
  { name: "Calmness", data: [3, 1, 1, 3, 3, 3, 1] },
  { name: "Caring", data: [7, 5, 5, 5, 2, 5, 1] },

  { name: "Certainty", data: [1, 1, 1, 1, 1, 1, 1] },
  { name: "Cheerful", data: [2, 5, 2, 6, 3, 5, 3] },
  { name: "Commitment", data: [1, 1, 1, 1, 7, 1, 1] },
  { name: "Compassion", data: [2, 6, 5, 6, 7, 6, 1] },
  { name: "Confidence", data: [2, 2, 2, 1, 2, 2, 1] },
  { name: "Consideration", data: [2, 3, 2, 6, 3, 3, 3] },
  { name: "Courage", data: [3, 6, 1, 1, 2, 3, 2] },

  { name: "Courtesy", data: [1, 6, 5, 1, 2, 6, 1] },
  { name: "Creativity", data: [4, 4, 4, 4, 2, 2, 2] },
  { name: "Decisiveness", data: [3, 3, 3, 3, 1, 3, 3] },
  { name: "Dedication", data: [1, 1, 1, 7, 7, 1, 1] },
  { name: "Delight", data: [4, 1, 1, 4, 4, 1, 3] },
  { name: "Dependability", data: [4, 3, 3, 3, 3, 1, 1] },
  { name: "Devotion", data: [1, 1, 7, 7, 7, 1, 1] },

  { name: "Dignity", data: [1, 1, 4, 1, 3, 1, 3] },
  { name: "Discipline", data: [1, 1, 1, 4, 1, 1, 1] },
  { name: "Eagerness", data: [4, 4, 2, 2, 4, 2, 4] },
  { name: "Elegance", data: [7, 4, 5, 3, 2, 2, 4] },
  { name: "Empathy", data: [7, 5, 5, 6, 6, 5, 5] },
  { name: "Enjoyment", data: [7, 5, 2, 6, 3, 5, 3] },
  { name: "Enthusiasm", data: [2, 2, 6, 2, 3, 2, 3] },

  { name: "Esteem", data: [7, 7, 2, 2, 7, 7, 2] },
  { name: "Excellence", data: [3, 1, 3, 3, 2, 2, 1] },
  { name: "Faith", data: [3, 3, 3, 3, 3, 3, 3] },
  { name: "Faithfulness", data: [1, 7, 7, 7, 7, 1, 1] },
  { name: "Fearlessness", data: [3, 1, 1, 1, 2, 3, 2] },
  { name: "Forgiveness", data: [7, 2, 2, 6, 2, 2, 2] },
  { name: "Friendliness", data: [2, 6, 2, 6, 6, 2, 3] },

  { name: "Giving", data: [7, 6, 6, 6, 7, 2, 7] },
  { name: "Grace", data: [7, 7, 7, 2, 7, 7, 7] },
  { name: "Gratitude", data: [2, 5, 5, 2, 5, 5, 3] },
  { name: "Growth", data: [7, 2, 3, 2, 1, 2, 2] },
  { name: "Happiness", data: [3, 6, 6, 6, 1, 6, 3] },
  { name: "Harmony", data: [7, 3, 7, 2, 1, 1, 1] },
  { name: "Health", data: [4, 4, 4, 4, 1, 1, 1] },

  { name: "Hope", data: [7, 4, 4, 1, 4, 4, 4] },
  { name: "Integrity", data: [7, 7, 7, 6, 7, 7, 1] },
  { name: "Joy", data: [4, 4, 6, 4, 1, 6, 3] },
  { name: "Kindness", data: [7, 5, 5, 5, 6, 5, 1] },
  { name: "Leadership", data: [7, 7, 7, 2, 4, 4, 4] },
  { name: "Love", data: [2, 2, 2, 2, 2, 2, 2] },
  { name: "SelfLove", data: [4, 2, 2, 4, 2, 2, 1] },

  { name: "Optimism", data: [7, 5, 6, 6, 1, 3, 2] },
  { name: "Order", data: [2, 2, 1, 2, 1, 2, 1] },
  { name: "Peace", data: [3, 3, 3, 3, 3, 3, 1] },
  { name: "Persistence", data: [1, 5, 5, 5, 5, 6, 3] },
  { name: "Positive", data: [3, 2, 2, 6, 2, 3, 2] },
  { name: "Process", data: [2, 7, 2, 7, 7, 3, 4] },
  { name: "Purposeful", data: [1, 1, 4, 1, 7, 7, 4] },

  { name: "Truth", data: [7, 7, 7, 7, 7, 7, 7] },
  //  {"UGH", data:[4,2,2,2,2,2,2]] - not sure regarding the color order of those shape - removing it.
];

export const BuildWordsList = (): WordGridItem[] => {
  return PredefinedWords.map((x: Grid7Word) => {
    return {
      Name: x.name,
      DisplayName: i18n.t("predefined_words."+x.name.toLowerCase()),

      ShapeColorList: x.data.map((y: number, i: number) => {
        return { Shape: y, Color: 7 - i } as GridItem;
      }),
    } as WordGridItem;
  });
};
export default PredefinedWords;
