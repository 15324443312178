import React, { useContext, useState } from "react";
import {
  GridItem,
  GridItem_Undefined,
} from "../../components/Grids/Core/GridItem/GridItem";
import { UserContext, userContext } from "../../Context/userContext";
import { LightLanguageEnum } from "../../components/Grids/Core/GridItem/EnumValues";
import LearnColorsShapes from "./LearnColorsShapes";
import { View, Text, ScrollView, FlatList } from "react-native";
import { styles } from "./LearnShapes.styles";
import LearnFormula from "./LearnFormula";
import LearnShapeText from "./LearnShapeText";
import ShapeColor from "../../components/Grids/Core/ShapeColor/ShapeColor";

export interface ILearnShapes {
  // onClick: (gridItem: GridItem) => void;
  Shape: number;
}

const LearnShapes = (props: ILearnShapes) => {
  const user = useContext<userContext>(UserContext);

  let maxColor = LightLanguageEnum.Colors.length;

  let ColorData: GridItem[] = LightLanguageEnum.Colors.slice(1, maxColor).map(
    (x: string, i: number) => {
      return { Shape: props.Shape, Color: i + 1 };
    }
  );

  return (
    <FlatList
      ListHeaderComponent={
        <>
          <View style={styles.header}>
            <ShapeColor
              gridItem={{ Shape: props.Shape, Color: GridItem_Undefined }}
              onClick={() => {}}
              readonly={true}
            />
          </View>
          <LearnFormula shape={props.Shape} />
          <LearnShapeText shape={props.Shape} />
        </>
      }
      data={[]}
      renderItem={({ item }) => <View></View>}
      ListFooterComponent={
        <LearnColorsShapes
          data={ColorData}
          onClick={() => {}}
          readonly={true}
        />
      }
    />

    // <ScrollView style={styles.container}>
    //   <LearnFormula shape={props.Shape} />
    //   <LearnShapeText shape={props.Shape} />
    //   <LearnColorsShapes data={ColorData} onClick={() => {}} readonly={true} />
    // </ScrollView>
  );
};

export default LearnShapes;
