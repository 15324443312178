const GridsLogs = false;
const AccountLogs = false;
const ScreenGridLogs = false;

const Logs = {
  ScreenGridLogs,
  GridsLogs,
  AccountLogs,
};

export default Logs;
