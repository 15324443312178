import { Platform, StyleSheet } from "react-native";
import GlobalParams from "../../../Globals/GlobalParams";

export const styles = StyleSheet.create({
  FullView: {
    flex: 1,
    backgroundColor: "whitesmoke",
  },
  // -----------
  Top: {
    flex: 1,
  },
  Center: {
    flex: 8,
    flexDirection: "row",
  },
  Bottom: {
    flex: 1,
  },
  // -----------
  Left: {
    flex: 1,
  },
  Main: {
    flex: Platform.OS === "web" ? 1 : 8,
    flexDirection: "row",
  },
  MainBorder: {
    borderColor: "black",
    borderWidth: 2,
  },
  Right: {
    flex: 1,
  },

  WordList: {
    flex: 1,
  },
  ShapeColor: {
    flex: 1,
  },
  //***************** Debug ***********/
  // -----------
  TopDebug: {
    backgroundColor: "red",
  },
  CenterDebug: {
    backgroundColor: "yellow",
  },
  BottomDebug: {
    backgroundColor: "green",
  },
  // -----------
  LeftDebug: {
    backgroundColor: "lightcoral",
  },
  MainDebug: {
    backgroundColor: "yellow",
  },
  RightDebug: {
    backgroundColor: "lightgreen",
  },
  // -----------
  WordListDebug: {
    backgroundColor: "peru",
  },
  ShapeColorDebug: {
    backgroundColor: "pink",
  },
});
