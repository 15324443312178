import React, { useContext, useState } from "react";
import {
  GridItem,
  GridItem_Undefined,
} from "../../components/Grids/Core/GridItem/GridItem";
import { UserContext, userContext } from "../../Context/userContext";
import { LightLanguageEnum } from "../../components/Grids/Core/GridItem/EnumValues";
import LearnColorsShapes from "./LearnColorsShapes";

export interface ILearnShape {
  onClick: (gridItem: GridItem) => void;
}

const LearnColor = (props: ILearnShape) => {
  const user = useContext<userContext>(UserContext);

  let maxColor = LightLanguageEnum.Colors.length;

  let ShapeData: GridItem[] = LightLanguageEnum.Colors.slice(1, maxColor).map(
    (x: string, i: number) => {
      return { Shape: GridItem_Undefined, Color: i + 1 };
    }
  );

  return <LearnColorsShapes data={ShapeData} onClick={props.onClick} readonly={false} />;
};

export default LearnColor;
