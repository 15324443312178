import { Pressable, View, Text, Image, Platform, Linking } from "react-native";
import { styles } from "./UpgradeScreen.styles";

import LL_Header from "./HeaderComponents/LL_Header";
import StoresLogo from "./HeaderComponents/StoresLogo";
import i18n from "../Lang/translations";
import { useContext } from "react";
import { UserContext, userContext } from "../Context/userContext";
import GlobalParams from "../components/Globals/GlobalParams";

const UpgradeScreen = () => {
  const user = useContext<userContext>(UserContext);

  const DoUpgrade = () => {
    if (Platform.OS === "ios") {
      // Linking.openURL(GlobalParams.GetIOSLink());// "market://details?id=com.Light.Language");
      Linking.openURL(
        "https://apps.apple.com/il/app/light-language/id1671357144"
      );
    }
    if (Platform.OS === "android") {
      Linking.openURL(
        "http://play.google.com/store/apps/details?id=com.Light.Language"
      );
    }
  };

  const upgradeButton = () => {
    return (
      <Pressable style={styles.button} onPress={DoUpgrade}>
        <Text style={styles.text}>{i18n.t("screens.upgrade.new_version")}</Text>

        <Text style={styles.text}>
          {i18n.t("screens.upgrade.click_upgrade")}
        </Text>

        <Image
          style={styles.image}
          source={require("../../assets/images/ll.png")}
        />
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      <LL_Header />
      {upgradeButton()}
      <StoresLogo />
    </View>
  );
};

export default UpgradeScreen;
