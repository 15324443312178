/*
 * enum number to name mapping is very important as it is being used for the image filenames.
 */
// export enum LightLanguageLevel {
//   Level1 = 1,
//   Level1_2 = 11,
//   Level2 = 2,
//   Level3 = 3,
// }
export var LightLanguageEnum = {
  Shapes: [
    'None',
    'Cube', // Level 1
    'Sphere',
    'Pyramid',
    'Cone',
    'Megaphone',
    'Focuscone',
    'Cylinder',

    'Dodecahedron', // level 2
    'Octahedron',
    'Icosahedron',
    'Mobius',
    'DoubleSpiral',
    'Torus',

    'Tetrahedron', // level 3 - temporary fix for hila
  ],

  Colors: [
    'Transparent',
    'Red', // Level 1
    'Orange',
    'Yellow',
    'Green',
    'Blue',
    'Purple',
    'White',

    'Peach', // level 2
    'Sunflower',
    'Pink',
    'Turquoise',
    'Indigo',
    'Pearl',
    'Silver',
    'Gold',
    'Gray',
    'Brown',
    'Black',
  ],
};
