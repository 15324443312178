import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  FullView: {
    flex: 1,
    backgroundColor: "whitesmoke",
  },
  // -----------
  Top: {
    flex: 1,
  },
  Center: {
    flex: 8,
    flexDirection: "row",
  },
  Bottom: {
    flex: 1,
  },
  // -----------
  Left: {
    flex: 1,
  },
  Main: {
    flex: 8,
    borderColor: "black",
    borderWidth: 2,
  },
  MainLarge: {
    flex: 1,
    borderColor: "black",
    borderWidth: 2,
  },
  Right: {
    flex: 1,
  },
  // -----------
  Selection: {
    flex: 2,
  },
  ShapeColor: {
    flex: 8,
    flexDirection: "row",
  },
  // -----------
  Color: {
    flex: 1,
  },
  Shape: {
    flex: 1,
  },
  //***************** Debug ***********/
  // -----------
  TopDebug: {
    backgroundColor: "red",
  },
  CenterDebug: {
    backgroundColor: "yellow",
  },
  BottomDebug: {
    backgroundColor: "green",
  },
  // -----------
  LeftDebug: {
    backgroundColor: "lightcoral",
  },
  MainDebug: {
    backgroundColor: "yellow",
  },
  RightDebug: {
    backgroundColor: "lightgreen",
  },
  // -----------
  SelectionDebug: {
    backgroundColor: "magenta",
  },
  ShapeColorDebug: {
    backgroundColor: "midnightblue",
  },
  // -----------
  ColorDebug: {
    backgroundColor: "peru",
  },
  ShapeDebug: {
    backgroundColor: "pink",
  },
});
