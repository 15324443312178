import { View, Text, Image, FlatList, Platform } from "react-native";
import {
  PredefineShapes,
  WordGridItem,
} from "../../components/Grids/Core/PredefineShapes/PredefineShapes";
import { styles } from "./LearnWords.styles";
import i18n from "../../Lang/translations";
import { useState } from "react";
import PredefinedWords, {
  BuildWordsList,
} from "../HeaderComponents/PredefinedWords";
import GlobalParams from "../../components/Globals/GlobalParams";

const LearnWords = () => {
  const Title = i18n.t("screens.learn.words");

  const [selectedWord, setSelectedWord] = useState<string>("");

  const WordsList = BuildWordsList();

  const header = () => {
    return (
      <View>
        <Text style={styles.title}>{Title}</Text>
        {/* <Text style={styles.desc}>{Desc}</Text> */}
      </View>
    );
  };

  const SetSelected = (item: WordGridItem | null, final: boolean) => {
    setSelectedWord(item ? item.Name : "");
  };

  return (
    <View style={styles.container}>
      {Platform.OS === "web" && GlobalParams.IsLargeScreen() && (
        <View style={styles.leftrigth}></View>
      )}

      <View style={styles.leftrigth}>
        <FlatList
          ListHeaderComponent={header()}
          data={[]}
          renderItem={({ item }) => <View></View>}
          ListFooterComponent={
            <PredefineShapes
              wordlist={WordsList}
              selectedWord={selectedWord}
              onClick={(item, final) => SetSelected(item, final)}
              showModal={false}
            />
          }
        />
      </View>
      {Platform.OS === "web" && GlobalParams.IsLargeScreen() && (
        <View style={styles.leftrigth}></View>
      )}
    </View>
  );
};

export default LearnWords;
