import { getLocales } from "expo-localization";
import { I18n } from "i18n-js";

import translationEN from "./en.json";
import translationHE from "./he.json";
import translationES from "./es.json";
import translationHR from "./hr.json";

export const translations = {
  en: translationEN,
  he: translationHE,
  es: translationES,
  hr: translationHR,
};

const i18n = new I18n(translations);
// Set the locale once at the beginning of your app.
// i18n.locale = "he";//Localization.locale;

export default i18n;
