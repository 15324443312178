export interface IShapesPath {
  string: any;
}

const ShapesPath = {
  black: require("../../../../../assets/images/shapes/black.png"),
  blue: require("../../../../../assets/images/shapes/blue.png"),
  brown: require("../../../../../assets/images/shapes/brown.png"),
  cone: require("../../../../../assets/images/shapes/cone.png"),
  cone_black: require("../../../../../assets/images/shapes/cone_black.png"),
  cone_blue: require("../../../../../assets/images/shapes/cone_blue.png"),
  cone_brown: require("../../../../../assets/images/shapes/cone_brown.png"),
  cone_gold: require("../../../../../assets/images/shapes/cone_gold.png"),
  cone_gray: require("../../../../../assets/images/shapes/cone_gray.png"),
  cone_green: require("../../../../../assets/images/shapes/cone_green.png"),
  cone_indigo: require("../../../../../assets/images/shapes/cone_indigo.png"),
  cone_orange: require("../../../../../assets/images/shapes/cone_orange.png"),
  cone_peach: require("../../../../../assets/images/shapes/cone_peach.png"),
  cone_pearl: require("../../../../../assets/images/shapes/cone_pearl.png"),
  cone_pink: require("../../../../../assets/images/shapes/cone_pink.png"),
  cone_purple: require("../../../../../assets/images/shapes/cone_purple.png"),
  cone_red: require("../../../../../assets/images/shapes/cone_red.png"),
  cone_silver: require("../../../../../assets/images/shapes/cone_silver.png"),
  cone_sunflower: require("../../../../../assets/images/shapes/cone_sunflower.png"),
  cone_transparent: require("../../../../../assets/images/shapes/cone_transparent.png"),
  cone_turquoise: require("../../../../../assets/images/shapes/cone_turquoise.png"),
  cone_white: require("../../../../../assets/images/shapes/cone_white.png"),
  cone_yellow: require("../../../../../assets/images/shapes/cone_yellow.png"),
  cube: require("../../../../../assets/images/shapes/cube.png"),
  cube_black: require("../../../../../assets/images/shapes/cube_black.png"),
  cube_blue: require("../../../../../assets/images/shapes/cube_blue.png"),
  cube_brown: require("../../../../../assets/images/shapes/cube_brown.png"),
  cube_gold: require("../../../../../assets/images/shapes/cube_gold.png"),
  cube_gray: require("../../../../../assets/images/shapes/cube_gray.png"),
  cube_green: require("../../../../../assets/images/shapes/cube_green.png"),
  cube_indigo: require("../../../../../assets/images/shapes/cube_indigo.png"),
  cube_orange: require("../../../../../assets/images/shapes/cube_orange.png"),
  cube_peach: require("../../../../../assets/images/shapes/cube_peach.png"),
  cube_pearl: require("../../../../../assets/images/shapes/cube_pearl.png"),
  cube_pink: require("../../../../../assets/images/shapes/cube_pink.png"),
  cube_purple: require("../../../../../assets/images/shapes/cube_purple.png"),
  cube_red: require("../../../../../assets/images/shapes/cube_red.png"),
  cube_silver: require("../../../../../assets/images/shapes/cube_silver.png"),
  cube_sunflower: require("../../../../../assets/images/shapes/cube_sunflower.png"),
  cube_transparent: require("../../../../../assets/images/shapes/cube_transparent.png"),
  cube_turquoise: require("../../../../../assets/images/shapes/cube_turquoise.png"),
  cube_white: require("../../../../../assets/images/shapes/cube_white.png"),
  cube_yellow: require("../../../../../assets/images/shapes/cube_yellow.png"),
  cylinder: require("../../../../../assets/images/shapes/cylinder.png"),
  cylinder_black: require("../../../../../assets/images/shapes/cylinder_black.png"),
  cylinder_blue: require("../../../../../assets/images/shapes/cylinder_blue.png"),
  cylinder_brown: require("../../../../../assets/images/shapes/cylinder_brown.png"),
  cylinder_gold: require("../../../../../assets/images/shapes/cylinder_gold.png"),
  cylinder_gray: require("../../../../../assets/images/shapes/cylinder_gray.png"),
  cylinder_green: require("../../../../../assets/images/shapes/cylinder_green.png"),
  cylinder_indigo: require("../../../../../assets/images/shapes/cylinder_indigo.png"),
  cylinder_orange: require("../../../../../assets/images/shapes/cylinder_orange.png"),
  cylinder_peach: require("../../../../../assets/images/shapes/cylinder_peach.png"),
  cylinder_pearl: require("../../../../../assets/images/shapes/cylinder_pearl.png"),
  cylinder_pink: require("../../../../../assets/images/shapes/cylinder_pink.png"),
  cylinder_purple: require("../../../../../assets/images/shapes/cylinder_purple.png"),
  cylinder_red: require("../../../../../assets/images/shapes/cylinder_red.png"),
  cylinder_silver: require("../../../../../assets/images/shapes/cylinder_silver.png"),
  cylinder_sunflower: require("../../../../../assets/images/shapes/cylinder_sunflower.png"),
  cylinder_transparent: require("../../../../../assets/images/shapes/cylinder_transparent.png"),
  cylinder_turquoise: require("../../../../../assets/images/shapes/cylinder_turquoise.png"),
  cylinder_white: require("../../../../../assets/images/shapes/cylinder_white.png"),
  cylinder_yellow: require("../../../../../assets/images/shapes/cylinder_yellow.png"),
  dodecahedron: require("../../../../../assets/images/shapes/dodecahedron.png"),
  dodecahedron_black: require("../../../../../assets/images/shapes/dodecahedron_black.png"),
  dodecahedron_blue: require("../../../../../assets/images/shapes/dodecahedron_blue.png"),
  dodecahedron_brown: require("../../../../../assets/images/shapes/dodecahedron_brown.png"),
  dodecahedron_gold: require("../../../../../assets/images/shapes/dodecahedron_gold.png"),
  dodecahedron_gray: require("../../../../../assets/images/shapes/dodecahedron_gray.png"),
  dodecahedron_green: require("../../../../../assets/images/shapes/dodecahedron_green.png"),
  dodecahedron_indigo: require("../../../../../assets/images/shapes/dodecahedron_indigo.png"),
  dodecahedron_orange: require("../../../../../assets/images/shapes/dodecahedron_orange.png"),
  dodecahedron_peach: require("../../../../../assets/images/shapes/dodecahedron_peach.png"),
  dodecahedron_pearl: require("../../../../../assets/images/shapes/dodecahedron_pearl.png"),
  dodecahedron_pink: require("../../../../../assets/images/shapes/dodecahedron_pink.png"),
  dodecahedron_purple: require("../../../../../assets/images/shapes/dodecahedron_purple.png"),
  dodecahedron_red: require("../../../../../assets/images/shapes/dodecahedron_red.png"),
  dodecahedron_silver: require("../../../../../assets/images/shapes/dodecahedron_silver.png"),
  dodecahedron_sunflower: require("../../../../../assets/images/shapes/dodecahedron_sunflower.png"),
  dodecahedron_transparent: require("../../../../../assets/images/shapes/dodecahedron_transparent.png"),
  dodecahedron_turquoise: require("../../../../../assets/images/shapes/dodecahedron_turquoise.png"),
  dodecahedron_white: require("../../../../../assets/images/shapes/dodecahedron_white.png"),
  dodecahedron_yellow: require("../../../../../assets/images/shapes/dodecahedron_yellow.png"),
  doublespiral: require("../../../../../assets/images/shapes/doublespiral.png"),
  doublespiral_black: require("../../../../../assets/images/shapes/doublespiral_black.png"),
  doublespiral_blue: require("../../../../../assets/images/shapes/doublespiral_blue.png"),
  doublespiral_brown: require("../../../../../assets/images/shapes/doublespiral_brown.png"),
  doublespiral_gold: require("../../../../../assets/images/shapes/doublespiral_gold.png"),
  doublespiral_gray: require("../../../../../assets/images/shapes/doublespiral_gray.png"),
  doublespiral_green: require("../../../../../assets/images/shapes/doublespiral_green.png"),
  doublespiral_indigo: require("../../../../../assets/images/shapes/doublespiral_indigo.png"),
  doublespiral_orange: require("../../../../../assets/images/shapes/doublespiral_orange.png"),
  doublespiral_peach: require("../../../../../assets/images/shapes/doublespiral_peach.png"),
  doublespiral_pearl: require("../../../../../assets/images/shapes/doublespiral_pearl.png"),
  doublespiral_pink: require("../../../../../assets/images/shapes/doublespiral_pink.png"),
  doublespiral_purple: require("../../../../../assets/images/shapes/doublespiral_purple.png"),
  doublespiral_red: require("../../../../../assets/images/shapes/doublespiral_red.png"),
  doublespiral_silver: require("../../../../../assets/images/shapes/doublespiral_silver.png"),
  doublespiral_sunflower: require("../../../../../assets/images/shapes/doublespiral_sunflower.png"),
  doublespiral_transparent: require("../../../../../assets/images/shapes/doublespiral_transparent.png"),
  doublespiral_turquoise: require("../../../../../assets/images/shapes/doublespiral_turquoise.png"),
  doublespiral_white: require("../../../../../assets/images/shapes/doublespiral_white.png"),
  doublespiral_yellow: require("../../../../../assets/images/shapes/doublespiral_yellow.png"),
  focuscone: require("../../../../../assets/images/shapes/focuscone.png"),
  focuscone_black: require("../../../../../assets/images/shapes/focuscone_black.png"),
  focuscone_blue: require("../../../../../assets/images/shapes/focuscone_blue.png"),
  focuscone_brown: require("../../../../../assets/images/shapes/focuscone_brown.png"),
  focuscone_gold: require("../../../../../assets/images/shapes/focuscone_gold.png"),
  focuscone_gray: require("../../../../../assets/images/shapes/focuscone_gray.png"),
  focuscone_green: require("../../../../../assets/images/shapes/focuscone_green.png"),
  focuscone_indigo: require("../../../../../assets/images/shapes/focuscone_indigo.png"),
  focuscone_orange: require("../../../../../assets/images/shapes/focuscone_orange.png"),
  focuscone_peach: require("../../../../../assets/images/shapes/focuscone_peach.png"),
  focuscone_pearl: require("../../../../../assets/images/shapes/focuscone_pearl.png"),
  focuscone_pink: require("../../../../../assets/images/shapes/focuscone_pink.png"),
  focuscone_purple: require("../../../../../assets/images/shapes/focuscone_purple.png"),
  focuscone_red: require("../../../../../assets/images/shapes/focuscone_red.png"),
  focuscone_silver: require("../../../../../assets/images/shapes/focuscone_silver.png"),
  focuscone_sunflower: require("../../../../../assets/images/shapes/focuscone_sunflower.png"),
  focuscone_transparent: require("../../../../../assets/images/shapes/focuscone_transparent.png"),
  focuscone_turquoise: require("../../../../../assets/images/shapes/focuscone_turquoise.png"),
  focuscone_white: require("../../../../../assets/images/shapes/focuscone_white.png"),
  focuscone_yellow: require("../../../../../assets/images/shapes/focuscone_yellow.png"),
  gold: require("../../../../../assets/images/shapes/gold.png"),
  gray: require("../../../../../assets/images/shapes/gray.png"),
  green: require("../../../../../assets/images/shapes/green.png"),
  icosahedron: require("../../../../../assets/images/shapes/icosahedron.png"),
  icosahedron_black: require("../../../../../assets/images/shapes/icosahedron_black.png"),
  icosahedron_blue: require("../../../../../assets/images/shapes/icosahedron_blue.png"),
  icosahedron_brown: require("../../../../../assets/images/shapes/icosahedron_brown.png"),
  icosahedron_gold: require("../../../../../assets/images/shapes/icosahedron_gold.png"),
  icosahedron_gray: require("../../../../../assets/images/shapes/icosahedron_gray.png"),
  icosahedron_green: require("../../../../../assets/images/shapes/icosahedron_green.png"),
  icosahedron_indigo: require("../../../../../assets/images/shapes/icosahedron_indigo.png"),
  icosahedron_orange: require("../../../../../assets/images/shapes/icosahedron_orange.png"),
  icosahedron_peach: require("../../../../../assets/images/shapes/icosahedron_peach.png"),
  icosahedron_pearl: require("../../../../../assets/images/shapes/icosahedron_pearl.png"),
  icosahedron_pink: require("../../../../../assets/images/shapes/icosahedron_pink.png"),
  icosahedron_purple: require("../../../../../assets/images/shapes/icosahedron_purple.png"),
  icosahedron_red: require("../../../../../assets/images/shapes/icosahedron_red.png"),
  icosahedron_silver: require("../../../../../assets/images/shapes/icosahedron_silver.png"),
  icosahedron_sunflower: require("../../../../../assets/images/shapes/icosahedron_sunflower.png"),
  icosahedron_transparent: require("../../../../../assets/images/shapes/icosahedron_transparent.png"),
  icosahedron_turquoise: require("../../../../../assets/images/shapes/icosahedron_turquoise.png"),
  icosahedron_white: require("../../../../../assets/images/shapes/icosahedron_white.png"),
  icosahedron_yellow: require("../../../../../assets/images/shapes/icosahedron_yellow.png"),
  indigo: require("../../../../../assets/images/shapes/indigo.png"),
  megaphone: require("../../../../../assets/images/shapes/megaphone.png"),
  megaphone_black: require("../../../../../assets/images/shapes/megaphone_black.png"),
  megaphone_blue: require("../../../../../assets/images/shapes/megaphone_blue.png"),
  megaphone_brown: require("../../../../../assets/images/shapes/megaphone_brown.png"),
  megaphone_gold: require("../../../../../assets/images/shapes/megaphone_gold.png"),
  megaphone_gray: require("../../../../../assets/images/shapes/megaphone_gray.png"),
  megaphone_green: require("../../../../../assets/images/shapes/megaphone_green.png"),
  megaphone_indigo: require("../../../../../assets/images/shapes/megaphone_indigo.png"),
  megaphone_orange: require("../../../../../assets/images/shapes/megaphone_orange.png"),
  megaphone_peach: require("../../../../../assets/images/shapes/megaphone_peach.png"),
  megaphone_pearl: require("../../../../../assets/images/shapes/megaphone_pearl.png"),
  megaphone_pink: require("../../../../../assets/images/shapes/megaphone_pink.png"),
  megaphone_purple: require("../../../../../assets/images/shapes/megaphone_purple.png"),
  megaphone_red: require("../../../../../assets/images/shapes/megaphone_red.png"),
  megaphone_silver: require("../../../../../assets/images/shapes/megaphone_silver.png"),
  megaphone_sunflower: require("../../../../../assets/images/shapes/megaphone_sunflower.png"),
  megaphone_transparent: require("../../../../../assets/images/shapes/megaphone_transparent.png"),
  megaphone_turquoise: require("../../../../../assets/images/shapes/megaphone_turquoise.png"),
  megaphone_white: require("../../../../../assets/images/shapes/megaphone_white.png"),
  megaphone_yellow: require("../../../../../assets/images/shapes/megaphone_yellow.png"),
  mobius: require("../../../../../assets/images/shapes/mobius.png"),
  mobius_black: require("../../../../../assets/images/shapes/mobius_black.png"),
  mobius_blue: require("../../../../../assets/images/shapes/mobius_blue.png"),
  mobius_brown: require("../../../../../assets/images/shapes/mobius_brown.png"),
  mobius_gold: require("../../../../../assets/images/shapes/mobius_gold.png"),
  mobius_gray: require("../../../../../assets/images/shapes/mobius_gray.png"),
  mobius_green: require("../../../../../assets/images/shapes/mobius_green.png"),
  mobius_indigo: require("../../../../../assets/images/shapes/mobius_indigo.png"),
  mobius_orange: require("../../../../../assets/images/shapes/mobius_orange.png"),
  mobius_peach: require("../../../../../assets/images/shapes/mobius_peach.png"),
  mobius_pearl: require("../../../../../assets/images/shapes/mobius_pearl.png"),
  mobius_pink: require("../../../../../assets/images/shapes/mobius_pink.png"),
  mobius_purple: require("../../../../../assets/images/shapes/mobius_purple.png"),
  mobius_red: require("../../../../../assets/images/shapes/mobius_red.png"),
  mobius_silver: require("../../../../../assets/images/shapes/mobius_silver.png"),
  mobius_sunflower: require("../../../../../assets/images/shapes/mobius_sunflower.png"),
  mobius_transparent: require("../../../../../assets/images/shapes/mobius_transparent.png"),
  mobius_turquoise: require("../../../../../assets/images/shapes/mobius_turquoise.png"),
  mobius_white: require("../../../../../assets/images/shapes/mobius_white.png"),
  mobius_yellow: require("../../../../../assets/images/shapes/mobius_yellow.png"),
  none: require("../../../../../assets/images/shapes/none.png"),
  none_black: require("../../../../../assets/images/shapes/none_black.png"),
  none_blue: require("../../../../../assets/images/shapes/none_blue.png"),
  none_brown: require("../../../../../assets/images/shapes/none_brown.png"),
  none_gold: require("../../../../../assets/images/shapes/none_gold.png"),
  none_gray: require("../../../../../assets/images/shapes/none_gray.png"),
  none_green: require("../../../../../assets/images/shapes/none_green.png"),
  none_indigo: require("../../../../../assets/images/shapes/none_indigo.png"),
  none_orange: require("../../../../../assets/images/shapes/none_orange.png"),
  none_peach: require("../../../../../assets/images/shapes/none_peach.png"),
  none_pearl: require("../../../../../assets/images/shapes/none_pearl.png"),
  none_pink: require("../../../../../assets/images/shapes/none_pink.png"),
  none_purple: require("../../../../../assets/images/shapes/none_purple.png"),
  none_red: require("../../../../../assets/images/shapes/none_red.png"),
  none_silver: require("../../../../../assets/images/shapes/none_silver.png"),
  none_sunflower: require("../../../../../assets/images/shapes/none_sunflower.png"),
  none_transparent: require("../../../../../assets/images/shapes/none_transparent.png"),
  none_turquoise: require("../../../../../assets/images/shapes/none_turquoise.png"),
  none_white: require("../../../../../assets/images/shapes/none_white.png"),
  none_yellow: require("../../../../../assets/images/shapes/none_yellow.png"),
  octahedron: require("../../../../../assets/images/shapes/octahedron.png"),
  octahedron_black: require("../../../../../assets/images/shapes/octahedron_black.png"),
  octahedron_blue: require("../../../../../assets/images/shapes/octahedron_blue.png"),
  octahedron_brown: require("../../../../../assets/images/shapes/octahedron_brown.png"),
  octahedron_gold: require("../../../../../assets/images/shapes/octahedron_gold.png"),
  octahedron_gray: require("../../../../../assets/images/shapes/octahedron_gray.png"),
  octahedron_green: require("../../../../../assets/images/shapes/octahedron_green.png"),
  octahedron_indigo: require("../../../../../assets/images/shapes/octahedron_indigo.png"),
  octahedron_orange: require("../../../../../assets/images/shapes/octahedron_orange.png"),
  octahedron_peach: require("../../../../../assets/images/shapes/octahedron_peach.png"),
  octahedron_pearl: require("../../../../../assets/images/shapes/octahedron_pearl.png"),
  octahedron_pink: require("../../../../../assets/images/shapes/octahedron_pink.png"),
  octahedron_purple: require("../../../../../assets/images/shapes/octahedron_purple.png"),
  octahedron_red: require("../../../../../assets/images/shapes/octahedron_red.png"),
  octahedron_silver: require("../../../../../assets/images/shapes/octahedron_silver.png"),
  octahedron_sunflower: require("../../../../../assets/images/shapes/octahedron_sunflower.png"),
  octahedron_transparent: require("../../../../../assets/images/shapes/octahedron_transparent.png"),
  octahedron_turquoise: require("../../../../../assets/images/shapes/octahedron_turquoise.png"),
  octahedron_white: require("../../../../../assets/images/shapes/octahedron_white.png"),
  octahedron_yellow: require("../../../../../assets/images/shapes/octahedron_yellow.png"),
  orange: require("../../../../../assets/images/shapes/orange.png"),
  peach: require("../../../../../assets/images/shapes/peach.png"),
  pearl: require("../../../../../assets/images/shapes/pearl.png"),
  pink: require("../../../../../assets/images/shapes/pink.png"),
  purple: require("../../../../../assets/images/shapes/purple.png"),
  pyramid: require("../../../../../assets/images/shapes/pyramid.png"),
  pyramid_black: require("../../../../../assets/images/shapes/pyramid_black.png"),
  pyramid_blue: require("../../../../../assets/images/shapes/pyramid_blue.png"),
  pyramid_brown: require("../../../../../assets/images/shapes/pyramid_brown.png"),
  pyramid_gold: require("../../../../../assets/images/shapes/pyramid_gold.png"),
  pyramid_gray: require("../../../../../assets/images/shapes/pyramid_gray.png"),
  pyramid_green: require("../../../../../assets/images/shapes/pyramid_green.png"),
  pyramid_indigo: require("../../../../../assets/images/shapes/pyramid_indigo.png"),
  pyramid_orange: require("../../../../../assets/images/shapes/pyramid_orange.png"),
  pyramid_peach: require("../../../../../assets/images/shapes/pyramid_peach.png"),
  pyramid_pearl: require("../../../../../assets/images/shapes/pyramid_pearl.png"),
  pyramid_pink: require("../../../../../assets/images/shapes/pyramid_pink.png"),
  pyramid_purple: require("../../../../../assets/images/shapes/pyramid_purple.png"),
  pyramid_red: require("../../../../../assets/images/shapes/pyramid_red.png"),
  pyramid_silver: require("../../../../../assets/images/shapes/pyramid_silver.png"),
  pyramid_sunflower: require("../../../../../assets/images/shapes/pyramid_sunflower.png"),
  pyramid_transparent: require("../../../../../assets/images/shapes/pyramid_transparent.png"),
  pyramid_turquoise: require("../../../../../assets/images/shapes/pyramid_turquoise.png"),
  pyramid_white: require("../../../../../assets/images/shapes/pyramid_white.png"),
  pyramid_yellow: require("../../../../../assets/images/shapes/pyramid_yellow.png"),
  red: require("../../../../../assets/images/shapes/red.png"),
  silver: require("../../../../../assets/images/shapes/silver.png"),
  sphere: require("../../../../../assets/images/shapes/sphere.png"),
  sphere_black: require("../../../../../assets/images/shapes/sphere_black.png"),
  sphere_blue: require("../../../../../assets/images/shapes/sphere_blue.png"),
  sphere_brown: require("../../../../../assets/images/shapes/sphere_brown.png"),
  sphere_gold: require("../../../../../assets/images/shapes/sphere_gold.png"),
  sphere_gray: require("../../../../../assets/images/shapes/sphere_gray.png"),
  sphere_green: require("../../../../../assets/images/shapes/sphere_green.png"),
  sphere_indigo: require("../../../../../assets/images/shapes/sphere_indigo.png"),
  sphere_orange: require("../../../../../assets/images/shapes/sphere_orange.png"),
  sphere_peach: require("../../../../../assets/images/shapes/sphere_peach.png"),
  sphere_pearl: require("../../../../../assets/images/shapes/sphere_pearl.png"),
  sphere_pink: require("../../../../../assets/images/shapes/sphere_pink.png"),
  sphere_purple: require("../../../../../assets/images/shapes/sphere_purple.png"),
  sphere_red: require("../../../../../assets/images/shapes/sphere_red.png"),
  sphere_silver: require("../../../../../assets/images/shapes/sphere_silver.png"),
  sphere_sunflower: require("../../../../../assets/images/shapes/sphere_sunflower.png"),
  sphere_transparent: require("../../../../../assets/images/shapes/sphere_transparent.png"),
  sphere_turquoise: require("../../../../../assets/images/shapes/sphere_turquoise.png"),
  sphere_white: require("../../../../../assets/images/shapes/sphere_white.png"),
  sphere_yellow: require("../../../../../assets/images/shapes/sphere_yellow.png"),
  sunflower: require("../../../../../assets/images/shapes/sunflower.png"),
  torus: require("../../../../../assets/images/shapes/torus.png"),
  torus_black: require("../../../../../assets/images/shapes/torus_black.png"),
  torus_blue: require("../../../../../assets/images/shapes/torus_blue.png"),
  torus_brown: require("../../../../../assets/images/shapes/torus_brown.png"),
  torus_gold: require("../../../../../assets/images/shapes/torus_gold.png"),
  torus_gray: require("../../../../../assets/images/shapes/torus_gray.png"),
  torus_green: require("../../../../../assets/images/shapes/torus_green.png"),
  torus_indigo: require("../../../../../assets/images/shapes/torus_indigo.png"),
  torus_orange: require("../../../../../assets/images/shapes/torus_orange.png"),
  torus_peach: require("../../../../../assets/images/shapes/torus_peach.png"),
  torus_pearl: require("../../../../../assets/images/shapes/torus_pearl.png"),
  torus_pink: require("../../../../../assets/images/shapes/torus_pink.png"),
  torus_purple: require("../../../../../assets/images/shapes/torus_purple.png"),
  torus_red: require("../../../../../assets/images/shapes/torus_red.png"),
  torus_silver: require("../../../../../assets/images/shapes/torus_silver.png"),
  torus_sunflower: require("../../../../../assets/images/shapes/torus_sunflower.png"),
  torus_transparent: require("../../../../../assets/images/shapes/torus_transparent.png"),
  torus_turquoise: require("../../../../../assets/images/shapes/torus_turquoise.png"),
  torus_white: require("../../../../../assets/images/shapes/torus_white.png"),
  torus_yellow: require("../../../../../assets/images/shapes/torus_yellow.png"),
  transparent: require("../../../../../assets/images/shapes/transparent.png"),
  turquoise: require("../../../../../assets/images/shapes/turquoise.png"),
  white: require("../../../../../assets/images/shapes/white.png"),
  yellow: require("../../../../../assets/images/shapes/yellow.png"),
  tetrahedron_pearl: require("../../../../../assets/images/shapes/tetrahedron_pearl.png"),

};
export default ShapesPath;
