import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    // flex: 1,
    alignSelf: "stretch",
    // justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "lightblue",
    backgroundColor: "lightgreen",
  },

  store: {
    // flex: 1,
    // position: "absolute",
    // bottom: 0,
    // left: 0,
    // right: 0,
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "space-evenly",
    // alignItems: "stretch",
    // alignContent: "stretch",
    // alignContent:"stretch",
    backgroundColor: "linen",//"yellow",
  },
  viewimagetext: {
    flexDirection: "row",
    justifyContent: "center",
    // alignItems:"flex-start",
    alignItems: "center",
    borderColor: "darkgray",
    borderWidth: 2,
    borderRadius: 10,
    padding: 5,
    margin: 10,
    backgroundColor: "palegreen",
  },
  text: {
    fontSize: 16,
    color: "black",
    marginHorizontal: 5,
    fontWeight: "bold",
  },
  image: {
    width: 32,
    height: 32,
    // margin: 6,
    marginHorizontal: 5,
  },
});
