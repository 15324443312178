import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: "center",
    // justifyContent: "space-between",
    // alignItems: "stretch",
    // alignContent: "stretch",
    // flexDirection: "column",
  },
  dailygrid: {
    flex: 1,
    alignItems: "center",
  },
  


  imageIcon: {
    width: 24,
    height: 24,
    margin: 6,
  },
});
