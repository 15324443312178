import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { useQuery } from "react-query";
import AccoutService from "../api/services/Account";
import { AccountErrors, LoginResponse } from "../api/types/Accountypes";
import { UserContext, userContext } from "../Context/userContext";
import { RootStackNavigationProp } from "../Navigation/types";
import LL_Header from "./HeaderComponents/LL_Header";
import { styles } from "./LoginScreen.styles";
import i18n from "../Lang/translations";
import StoresLogo from "./HeaderComponents/StoresLogo";

const LoginScreen = () => {
  const user = useContext<userContext>(UserContext);
  const navigation = useNavigation<RootStackNavigationProp>();

  const [email, setEmail] = useState<string>(""); //abab"); //d@com");
  const [password, setPassword] = useState<string>(""); //1234");
  const [loginEnabled, setLoginEnabled] = useState<boolean>(false);

  const [errorText, setErrorText] = useState<string>("");
  const timer = useRef<NodeJS.Timer>();

  useEffect(() => {
    setLoginEnabled(email !== "" && password !== "");
  }, [email, password]);

  const { isLoading: isLoadingTutorials, refetch: sendLogin } = useQuery<
    LoginResponse,
    Error
  >(
    "query-login",
    async () => {
      return await AccoutService.login(email.trim(), password.trim());
    },
    {
      enabled: false,
      retry: 0,
      onSuccess: (res) => {
        LoginSuccess(res);
      },
      onError: (err: any) => {
        LoginError(err);
      },
    }
  );

  const setError = (err: string) => {
    setErrorText(err);

    timer.current = setInterval(() => {
      clearError();
    }, 5000);
  };

  const clearError = () => {
    setErrorText("");
    clearInterval(timer.current);
  };

  const LoginSuccess = (login: LoginResponse): void => {
    if (AccoutService.HandleLoginResponse(login, user) === AccountErrors.OK) {
      if (login.Upgrade) {
        navigation.navigate("Upgrade");
      } else {
        navigation.navigate("Home");
      }
    } else {
      setError(i18n.t("screens.login.err_login"));
      // if (Platform.OS === "web") {
      //   alert("Login Failed - Check email and password");
      // } else {
      //   Alert.alert("Login Failed", "Check email and password");
      //}
    }
  };

  const LoginError = (err: any): void => {
    console.log("Login Error");
  };

  const DoLogin = () => {
    console.log("DoLogin: " + email + " pass: " + password);
    clearError();
    try {
      sendLogin();
    } catch (error) {}
  };

  const loginForm = () => {
    return (
      <View style={styles.sectionMain}>
        <View style={styles.textLine}>
          {/* <Text style={styles.textLineText}>Name: </Text> */}
          <Image
            style={styles.imageIcon}
            source={require("../../assets/images/login/email.png")}
          />
          <TextInput
            style={styles.textLineInput}
            placeholder={i18n.t("screens.login.youremail")}
            onChangeText={(newText) => setEmail(newText)}
            value={email}
          />
        </View>
        <View style={styles.textLine}>
          <Image
            style={styles.imageIcon}
            source={require("../../assets/images/login/pass.png")}
          />
          {/* <Text style={styles.textLineText}>Title: </Text> */}
          <TextInput
            style={styles.textLineInput}
            secureTextEntry={true}
            onChangeText={(newText) => setPassword(newText)}
            value={password}
            placeholder={i18n.t("screens.login.yourpassword")}
          />
        </View>
        <Pressable
          onPress={() => {
            navigation.navigate("Register");
          }}
        >
          <Text style={styles.textLineTextRegister}>
            {i18n.t("screens.login.registerlink")}
          </Text>
        </Pressable>

        <Text style={[styles.textLineText, styles.textLineTextError]}>
          {errorText}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <LL_Header />
      {loginForm()}
      <StoresLogo />
      <View style={styles.footer}>
        <TouchableOpacity onPress={() => DoLogin()} disabled={!loginEnabled}>
          <Text style={styles.textLineText}>
            {i18n.t("screens.login.login")}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LoginScreen;
