import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  ShapeColorTextView: {
    // backgroundColor: 'skyblue',
    // backgroundColor: 'lime',
    // borderColor: 'black',
    // borderWidth: 1,
    // width: '100%',
    // height: '100%',
    // justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 2,
    padding: 2,
    // textDecorationColor: 'red',
  },
  ShapeColorText: {
    textAlign: 'center',
    // fontWeight: 'bold',
    // color: 'red',
  },
  Selected: {
     backgroundColor: 'lightblue',
  }
});
