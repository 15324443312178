import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    // flex: 1,
    alignSelf: "stretch",
    // justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "lightblue",
    backgroundColor: "linen",
  },

  container: {
    // flex: 1,
    // position: "absolute",
    // bottom: 0,
    // left: 0,
    // right: 0,
    flexDirection: "column",
    alignSelf: "stretch",

    // alignItems: "center",
    // alignContent: "stretch",
    // alignContent:"stretch",
    backgroundColor: "linen",//"brown",
  },
  viewimagetext:{
    // flex:1,
    flexDirection:"row",
    justifyContent: "space-evenly",
    
    alignItems: "center",

  },
  text:{
    fontSize: 18,
    // fontWeight: "bold",
    alignSelf: "center",

  },
  image: {
    width: 130,
    height: 40,
    margin: 6,
  },
});
