import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: "whitesmoke",
    flexDirection: "row",
    marginTop: 20,
  },
  // -----------
  Left: {
    flex: 1,
  },
  Main: {
    flex: 12,
    // borderColor: "black",
    // borderWidth: 2,
  },
  MainLarge: {
    flex: 3,
    // borderColor: "black",
    // borderWidth: 2,
  },
  Right: {
    flex: 1,
  },
  // -----------

  // container: {
  //   flex: 1,
  //   // alignItems: "center",
  //   // justifyContent: "center",

  //    alignItems: "stretch",
  //   //  alignContent: "stretch",
  //   // flexDirection: "column",
  // },
  // header: {
  //   // flex: 1,
  //   alignSelf: "stretch",
  //   // justifyContent: "center",
  //   alignItems: "center",
  //   // backgroundColor: "lightblue",
  //   backgroundColor: "lightgreen",
  // },
  // footer: {
  //   // flex: 1,
  //   alignSelf: "stretch",
  //   // justifyContent: "center",
  //   alignItems: "stretch",
  //   // backgroundColor: "lightblue",
  //   backgroundColor: "lightgreen",
  //   // margin:10,
  // },
  // sectionMain: {
  //   flex: 1,
  //   // alignSelf: "stretch",
  //   marginVertical:80,
  //   width:400,
  //   alignItems: "center",
  //   selfalign: "center",
  //   // borderColor:"darkgray",
  //   // borderWidth:1,
  //   // borderRadius:10,
  // },
  // sectionUpper: {
  //   flex: 1,
  //   // alignItems: "flex-start",
  //   justifyContent: "center",
  //   // backgroundColor: "lightblue",
  //   marginTop: 32,
  //   minHeight: 100, // to avoid keyboad covering input - android
  // },
  // sectionLower: {
  //   flex: 4,
  // },
  // main: {
  //   flex: 1,
  //   // backgroundColor: "yellow",
  //   // alignItems: "stretch",
  //   // alignContent: "stretch",
  //   borderColor: "black",
  //   borderwidth: 5,

  // },
  // textLineText: {
  //   // flex: 1,
  //   alignSelf: "center",// "flex-start",
  //   fontSize: 28,
  //   // backgroundColor: "lightbrown",
  //   margin:10,
  // },
  // textLineTextError  : {
  //   marginTop:30,
  //   color: "red",
  // },
  // textLineTextRegister:{
  //   alignSelf: "center",// "flex-start",
  //   fontSize: 18,
  //   // backgroundColor: "lightblue",
  //   marginTop:30,
  //   color: "blue",
  // },
  // textLine: {
  //   // flex: 1,
  //   marginHorizontal:50,
  //   marginVertical:10,
  //   borderWidth: 1,
  //   borderColor: "lightgray",
  //   borderRadius: 10,

  //   flexDirection: "row",
  //   fontSize: 20,

  //   alignItems: "stretch",
  //   alignContent: "stretch",
  //   // justifyContent: "center",

  //   // backgroundColor: "lightgreen",
  // },
  // textLineInput: {
  //   flex: 1,
  //   // backgroundColor: "lightgray",
  //   fontSize: 24,
  //   // marginHorizontal:50,
  //   // marginVertical:10,
  //   // borderBottomWidth: 1,

  // },
  // image: {
  //   width: 100,
  //   height: 100,
  //   marginTop:30,

  // },
  // imageIcon: {
  //   width: 24,
  //   height: 24,
  //   margin:6,

  // },
});
