import React from "react";
import { Image, TouchableOpacity } from "react-native";
import { GetGridItemImagePath, GridItem } from "../GridItem/GridItem";
import { styles } from "./ShapeColor.styles";
import ShapesPath from "./ShapesPath";

export interface ShapeColorProps {
  gridItem: GridItem;
  onClick: (gridItem: GridItem) => void;
  readonly: boolean;
  seleted: boolean;
}

const ShapeColor = (props: ShapeColorProps) => {
  const shapePath = GetGridItemImagePath(props.gridItem);

  type ShapePathObjectKey = keyof typeof ShapesPath;
  const imageKey = shapePath as ShapePathObjectKey;
  const imagePath = ShapesPath[imageKey];

  return (
    <TouchableOpacity
      style={styles.view}
      disabled={props.readonly}
      onPress={() => {
        props.onClick(props.gridItem);
        // console.log("ShapeColor: props: ", props.gridItem);
      }}
    >
      <Image source={imagePath} style={styles.image} />
    </TouchableOpacity>
  );
};

export default ShapeColor;
