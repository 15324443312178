import React, { useState } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import { GridType } from "../../../../api/types/GridsTypes";
import GlobalParams from "../../../Globals/GlobalParams";
import { LightLanguageEnum } from "../GridItem/EnumValues";
import { GridItem, GridItem_Undefined } from "../GridItem/GridItem";
import ShapeColorList, {
  ShapeColorListProps,
} from "../ShapeColorList/ShapeColorList";
import ShapeColorText from "../ShapeColorText/ShapeColorText";
import { styles } from "./ShapeColorSelection.styles";

export interface ShapeColorSelectionProps {
  level: GridType;
  gridItem: GridItem;
  onClick: (gridItem: GridItem) => void;
}

const ShapeColorSelection = (props: ShapeColorSelectionProps) => {
  const debug = false;
  // those are the defaults for Level1
  let maxColor: number = 8; /* 7 + 1 - transparent */
  let maxShape: number = 8; /* 7 + 1 - transparent */

  if (props.level === GridType.G49) {
    maxColor = LightLanguageEnum.Colors.length;
    /* remove the extra shape added for customer */
    maxShape = LightLanguageEnum.Shapes.length - 1;
  }

  // level: LightLanguageLevel.Level1,
  // maxColor: 8, /* 7 + 1 - transparent */
  // maxShape: 8, /* 7 + 1 - transparent */

  let ShapeData: GridItem[] = LightLanguageEnum.Shapes.slice(0, maxShape).map(
    (x: string, i: number) => {
      return { Shape: i, Color: GridItem_Undefined };
    }
  );
  let ColorData: GridItem[] = LightLanguageEnum.Colors.slice(0, maxColor).map(
    (x: string, i: number) => {
      return { Shape: GridItem_Undefined, Color: i };
    }
  );

  const [gridItem, setGridItem] = useState(props.gridItem);

  function handleClickShape(gridItemValue: GridItem) {
    const newGridItem: GridItem = {
      Shape: gridItemValue.Shape,
      Color: gridItem.Color,
    };
    setGridItem(newGridItem);
  }

  function handleClickColor(gridItemValue: GridItem) {
    const newGridItem: GridItem = {
      Shape: gridItem.Shape /*value.Shape*/,
      Color: gridItemValue.Color,
    };
    setGridItem(newGridItem);
  }

  const handleClose = () => {
    props.onClick(props.gridItem);
  };
  function handleClickSelect(_gridItemValue: GridItem) {
    console.log("handleClickSelect: ", gridItem); // + gridItem.Shape + " - " + gridItem.Color);
    props.onClick(gridItem);
  }

  var data = [
    { Shape: 1, Color: 0 },
    { Shape: 2, Color: 0 },
    { Shape: 3, Color: 1 },
    { Shape: 4, Color: 1 },
    { Shape: 5, Color: 2 },
    { Shape: 6, Color: 2 },
    { Shape: 7, Color: 3 },
    { Shape: 7, Color: 4 },
    { Shape: 6, Color: 5 },
    { Shape: 5, Color: 6 },
  ];

  // const listProps: ShapeColorListProps = {
  //   gridItem: { Shape: 0, Color: 0 },
  //   onClick: function (gridItem: GridItem): void {
  //     // setIsModalOpen(false);
  //     console.log("Function not implemented.");
  //   },
  //   gridItemList: data,
  //   showDescription: false,
  // };
  /*
+-----------------+
|       Top       |
+---+---------+---+
|   |   Sel   |   |
|   |         |   |
|   +----+----+   |
|   |    |    |   |
| L |  S |  C | R |
| E |  H |  O | I |    Center
| F |  A |  L | G |
| T |  P |  O | H |
|   |  E |  R | T |
|   |    |    |   |
|   |    |    |   |
|   |    |    |   |
+---+----+----+---+
|    Bottom       |
+-----------------+
  
      Main

Region:
Top, Center, Bottom     - Row  1,8,1
  Left, Main, Right     - Col  1,8,1
    Select, ShapeColor  - Row  2,8
      Shape, Color      - Col  1,1

*/
  return (
    <View style={styles.FullView}>
      <TouchableOpacity
        style={[styles.Top, debug && styles.TopDebug]}
        onPress={handleClose}
      >
        {debug && <Text>Top</Text>}
      </TouchableOpacity>
      <View style={[styles.Center, debug && styles.CenterDebug]}>
        <TouchableOpacity
          style={[styles.Left, debug && styles.LeftDebug]}
          onPress={handleClose}
        >
          {debug && <Text>Left</Text>}
        </TouchableOpacity>
        <View style={[GlobalParams.IsLargeScreen() ? styles.MainLarge : styles.Main, debug && styles.MainDebug]}>
          <View style={[styles.Selection, debug && styles.SelectionDebug]}>
            <ShapeColorText
              onClick={(value: GridItem) => handleClickSelect(value)}
              gridItem={gridItem}
              readonly={false}
              seleted={false}
            />
          </View>

          <View style={[styles.ShapeColor, debug && styles.ShapeColorDebug]}>
            <View style={[styles.Shape, debug && styles.ShapeDebug]}>
              <ShapeColorList
                gridItem={gridItem}
                gridItemList={ShapeData}
                onClick={(value: GridItem) => handleClickShape(value)}
                showDescription={false}
                readonly={false}
                showSelected={true}
              />
            </View>
            <View style={[styles.Color, debug && styles.ColorDebug]}>
              <ShapeColorList
                gridItem={gridItem}
                gridItemList={ColorData}
                onClick={(value: GridItem) => handleClickColor(value)}
                showDescription={false}
                readonly={false}
                showSelected={true}
              />
            </View>
          </View>
        </View>
        <TouchableOpacity
          style={[styles.Right, debug && styles.RightDebug]}
          onPress={handleClose}
        >
          {debug && <Text>Right</Text>}
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={[styles.Bottom, debug && styles.BottomDebug]}
        onPress={handleClose}
      >
        {debug && <Text>Bottom</Text>}
      </TouchableOpacity>
    </View>
  );
};

export default ShapeColorSelection;
