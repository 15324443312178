import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { View, Image, Text, TouchableOpacity, Platform } from "react-native";
import { UserContext, userContext } from "../Context/userContext";
import { Language } from "../Lang/Languages";
import i18n from "../Lang/translations";
import { RootStackNavigationProp } from "../Navigation/types";

import { styles } from "./ButtonHeaderControl.styles";
export interface IButtonItems {
  navigate: string | number;
  text: string;
  imageId: { source: any };
}

export interface IButtonHeaderControl {
  buttonItems: IButtonItems[];
  callback: (navigate: string | number) => void;
}

const ButtonHeaderControl = (props: IButtonHeaderControl) => {
  const user = useContext<userContext>(UserContext);
  const navigation = useNavigation<RootStackNavigationProp>();

  const gotoPage = async (navigate: string) => {
    navigation.navigate(navigate as any);
  };

  const writeText = (txt: string) => {
    return <Text style={styles.text}>{i18n.t(txt)}</Text>;
  };
  const renderLogo = (item: IButtonItems, i: number) => {
    return (
      <TouchableOpacity
        key={i.toString()}
        onPress={() => props.callback(item.navigate)}
      >
        <View style={styles.viewimagetext}>
          {user.Language === Language.he ? writeText(item.text) : null}
          <Image style={styles.image} {...item.imageId} />
          {user.Language !== Language.he ? writeText(item.text) : null}
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.store}>
      {props.buttonItems.map((item, i) => renderLogo(item, i))}
    </View>
  );
};

export default ButtonHeaderControl;
