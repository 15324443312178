import Constants from 'expo-constants';

const global_server_url = "https://www.lightlanguagegrid.com/";
const global_server_api_url = "https://www.lightlanguagegrid.com/api/";
//const global_server_api_url =  "https://localhost:44396/api/";

// const APPVERSION = "2.1.0";
const GridViewPrefix = "gridview";

var IOSLink = "";



var isLargeScreen = false;

function SetLargeScreen(large: boolean) {
  console.log("SetLargeScreen: " + large);
  isLargeScreen = large;
}

function IsLargeScreen(): boolean {
  console.log("SetLargeScreen: " + isLargeScreen);

  return isLargeScreen;
}

function SetIOSLink(link: string) {
  IOSLink = link;
}

function GetIOSLink() {
  return IOSLink;
}

function GetGridViewPrefix() {
  return GridViewPrefix;
}

function GetAppVersion() {
  //return APPVERSION;
  return Constants.manifest!.version 
}



function GetServerAPIURL() {
  return global_server_api_url;
}

function GetServerURL() {
  return global_server_url;
}


const GetShareUrl = (externalId: string): string => {
  return GetServerURL() + GridViewPrefix + "/" + externalId;
};

const GlobalParams = {
  GetServerURL,
  GetServerAPIURL,
  GetAppVersion,
  GetShareUrl,
  GetGridViewPrefix,
  SetIOSLink,
  GetIOSLink,
  SetLargeScreen,
  IsLargeScreen,
};

export default GlobalParams;
