import React, { useState } from "react";
import { Modal, View } from "react-native";
import GlobalParams from "../../Globals/GlobalParams";
import { GridItem } from "../Core/GridItem/GridItem";
import ShapeColor from "../Core/ShapeColor/ShapeColor";
import ShapeColorSelection from "../Core/ShapeColorSelection/ShapeColorSelection";
import { GridProps } from "../GridProps";

import { styles } from "./Grid7.styles";

const Grid7 = (props: GridProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [CurrentRow, setCurrentRow] = useState(0);

  /**
   * get the index of the selected ShapeColor / Row and save it.
   * @param rowIndex - the index of the selected row that was clicked.
   */
  function handleShapeColorClicked(rowIndex: number) {
    if (props.readonly) return;
    console.log("Grid7: (row)" + rowIndex);
    setCurrentRow(rowIndex);

    setIsModalOpen(true);
  }

  /**
   *
   * @param gridItem Called when the user selects a shape color in the opened Dialog box.
   */
  function ShapeColorSelected(gridItem: GridItem) {
    console.log("Grid7=> Row:" + CurrentRow + ",  shape: ", gridItem);

    if (
      props.data[CurrentRow].Shape !== gridItem.Shape ||
      props.data[CurrentRow].Color !== gridItem.Color
    ) {
      // Data has changed, update parent.
      let newData: GridItem[] = props.data.slice();
      newData[CurrentRow] = gridItem;
      props.setNewData(newData,true);
    }

    setIsModalOpen(false);
  }

  return (
    <View>
      <Modal
        animationType="fade"
        transparent={false}
        visible={isModalOpen}
        onRequestClose={() => {
          // Alert.alert("Modal has been closed.");
          setIsModalOpen(!isModalOpen);
        }}
      >
        <ShapeColorSelection
          onClick={(value: GridItem) => ShapeColorSelected(value)}
          gridItem={props.data[CurrentRow]}
          level={props.level}
        />
      </Modal>
      <View style={styles.grid7}>
        {[...Array(7)].map((item, i: number) => (
          // {props.data.map((item: GridItem, i: number) => (
          <ShapeColor
            key={i}
            onClick={() => handleShapeColorClicked(i)}
            gridItem={props.data[i]} //item}
            readonly={props.readonly}
            seleted={false}
          />
        ))}
      </View>
    </View>
  );
};

export default Grid7;
