import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {GetGridItemText} from '../GridItem/GridItem';
import ShapeColor, {ShapeColorProps} from '../ShapeColor/ShapeColor';
import {styles} from './ShapeColorText.styles';

const ShapeColorText = (props: ShapeColorProps) => {
  return (
    <View style={[styles.ShapeColorTextView, props.seleted && styles.Selected]}>
      <ShapeColor {...props} />
      <TouchableOpacity onPress={() => props.onClick(props.gridItem)}>
        <Text style={styles.ShapeColorText}>
          {GetGridItemText(props.gridItem)}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default ShapeColorText;
