import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { globalLogout, UserContext, userContext } from "../Context/userContext";
import { styles } from "./LoginLogoutButton.styles";
import { RootStackNavigationProp } from "./types";
import LanguageSelection from "./LanguageSelection";
import i18n from "../Lang/translations";

const LoginLogoutButton = () => {
  const navigation = useNavigation<RootStackNavigationProp>();
  const user = useContext<userContext>(UserContext);

  const Login = () => {
    console.log("Login: ");
    navigation.navigate("Login");
  };

  const Logout = () => {
    console.log("Home - Logout: ");
    globalLogout(user);
    navigation.navigate("Home");
  };

  const login_ui = () => {
    return (
      <TouchableOpacity style={styles.login_register} onPress={() => Login()}>

        <Image style={styles.image} source={require("../../assets/images/menu/login.png")} />
        <Text style={styles.textLineText}>{i18n.t("drawer.header.login")}</Text>
      </TouchableOpacity >
    );
  };

  const logout_ui = () => {
    return (
      <TouchableOpacity style={styles.login_register} onPress={() => Logout()}>
        <Image style={styles.image} source={require("../../assets/images/menu/logout.png")} />
        <Text style={styles.textLineText}>{i18n.t("drawer.header.logout")}</Text>
      </TouchableOpacity>
    );
  };
  return (
    <View style={styles.login_register}>
      <LanguageSelection />
      {user.Id ? logout_ui() : login_ui()}
    </View>
  );
};

export default LoginLogoutButton;
