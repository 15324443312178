import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: "center",
    // justifyContent: "space-evenly",
  },
  header: {
    // flex: 1,
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor: "red",
    margin: 10,
  },
});
