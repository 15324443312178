import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity, View, Text, Image } from "react-native";
import { RootStackNavigationProp } from "../Navigation/types";
import { styles } from "./NewGridScreen.styles";

import "react-native-get-random-values";
import { GridType } from "../api/types/GridsTypes";
import { GridData } from "../api/types/GridData";
import { useContext } from "react";
import { UserContext, userContext } from "../Context/userContext";
import i18n from "../Lang/translations";
import { GridItem } from "../components/Grids/Core/GridItem/GridItem";

const buttonItems = [
  {
    level: GridType.G7,
    dataSize: 7,
    label: "screens.newgrid.level1",

    imageId: { source: require("../../assets/images/new/ll1.png") },
  },
  {
    level: GridType.G7_PLUS,
    dataSize: 7,
    label: "screens.newgrid.level1_plus",
    imageId: { source: require("../../assets/images/new/ll1_2.png") },
  },
  {
    level: GridType.G49,
    dataSize: 49,
    label: "screens.newgrid.level2",
    imageId: { source: require("../../assets/images/new/ll2.png") },
  },
  {
    level: GridType.G144,
    dataSize: 144,
    label: "screens.newgrid.level3",
    imageId: { source: require("../../assets/images/new/ll3.png") },
  },
];

export default function NewGridScreen() {
  const user = useContext<userContext>(UserContext);

  const navigation = useNavigation<RootStackNavigationProp>();

  const nav = (item: any): void => {
    console.log("nav: ", item);

    const newGrid: GridData = {
      GridType: item.level,
      Id: 0,
      Data: [...Array(item.dataSize)].map((i, j) => {
        return { Shape: 0, Color: 0 } as GridItem;
      }),
      Title: "",
      Name: "",
    };
    user.setUserContext({ ...user, ActiveGrid: newGrid });

    navigation.navigate("Grid");
  };

  function renderGridItem(item: any) {
    const uri = "../../assets/images/ll" + item.level + ".png";
    return (
      <TouchableOpacity key={item.level.toString()} onPress={() => nav(item)}>
        <Text style={styles.text}>{i18n.t(item.label)}</Text>
        <Image style={styles.image} {...item.imageId} />
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.NewGridScreen}>
      {buttonItems.map((item) => renderGridItem(item))}
    </View>
  );
}
