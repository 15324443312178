import { View } from "react-native";
import React, { useContext, useState } from "react";
import { GridItem } from "../components/Grids/Core/GridItem/GridItem";
import { UserContext, userContext } from "../Context/userContext";
import LearnShapes from "./LearnComponents/LearnShapes";
import LearnColors from "./LearnComponents/LearnColors";
import LearnShape from "./LearnComponents/LearnShape";
import { styles } from "./LearnScreen.styles";
import LearnColor from "./LearnComponents/LearnColor";
import ButtonHeaderControl, {
  IButtonHeaderControl,
  IButtonItems,
} from "../Navigation/ButtonHeaderControl";
import LearnSetStage from "./LearnComponents/LearnSetStage";
import { LearnMode } from "./LearnMode";
import LearnWords from "./LearnComponents/LearnWords";

const LearnScreen = () => {
  const buttonItems: IButtonItems[] = [
    {
      navigate: LearnMode.Shape,
      text: "screens.learn.shapes",
      imageId: {
        source: require("../../assets/images/learn/shapes.png"),
      },
    },
    {
      navigate: LearnMode.Color,
      text: "screens.learn.colors",
      imageId: { source: require("../../assets/images/learn/colors.png") },
    },
  ];

  const buttonItems2: IButtonItems[] = [
    {
      navigate: LearnMode.Words,
      text: "screens.learn.words",
      imageId: {
        source: require("../../assets/images/learn/words.png"),
      },
    },
    {
      navigate: LearnMode.Sets,
      text: "screens.learn.sets",
      imageId: { source: require("../../assets/images/learn/sets.png") },
    },
    {
      navigate: LearnMode.Stages,
      text: "screens.learn.stages",
      imageId: { source: require("../../assets/images/learn/stages.png") },
    },
  ];

  const [shape, setShape] = useState(1);
  const [color, setColor] = useState(1);
  const [learnMode, SetLearnMode] = useState(LearnMode.Shape);

  const user = useContext<userContext>(UserContext);

  function handleClickShape(gridItemValue: GridItem) {
    setShape(gridItemValue.Shape);
    SetLearnMode(LearnMode.ShapeColor);

    console.log("handleClickShape");
  }

  function handleClickColor(gridItemValue: GridItem) {
    setColor(gridItemValue.Color);
    SetLearnMode(LearnMode.ColorShape);
    console.log("handleClickShape");
  }

  const switchMode = (mode: number | string) => {
    SetLearnMode(mode as LearnMode);
  };

  const ShapesColorsButton: IButtonHeaderControl = {
    buttonItems: buttonItems,
    callback: switchMode,
  };
  const ShapesColorsButton2: IButtonHeaderControl = {
    buttonItems: buttonItems2,
    callback: switchMode,
  };

  const learnSet = <LearnSetStage type={LearnMode.Sets} />;
  const learnStage = <LearnSetStage type={LearnMode.Stages} />;

  return (
    <View style={styles.container}>
      <ButtonHeaderControl {...ShapesColorsButton} />
      <ButtonHeaderControl {...ShapesColorsButton2} />

      {learnMode === LearnMode.Color && (
        <LearnColor onClick={handleClickColor} />
      )}
      {learnMode === LearnMode.Shape && (
        <LearnShape onClick={handleClickShape} />
      )}
      {learnMode === LearnMode.ShapeColor && <LearnShapes Shape={shape} />}
      {learnMode === LearnMode.ColorShape && <LearnColors Color={color} />}

      {learnMode === LearnMode.Sets && learnSet}
      {/* {learnMode === LearnMode.Sets && (
        <LearnSetStage type={LearnMode.Sets} />
      )} */}
      {learnMode === LearnMode.Stages && learnStage}

      {learnMode === LearnMode.Words && <LearnWords />}
    </View>
  );
};

export default LearnScreen;
