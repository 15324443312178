import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { View, Image, Text, TouchableOpacity, Platform } from "react-native";
import { UserContext, userContext } from "../../Context/userContext";
import { Language } from "../../Lang/Languages";
import i18n from "../../Lang/translations";
import { RootStackNavigationProp } from "../../Navigation/types";

import { styles } from "./NewOpenControl.styles";
import ButtonHeaderControl, {
  IButtonHeaderControl,
} from "../../Navigation/ButtonHeaderControl";
interface IButtonItems {
  navigate: string;
  text: string;
  imageId: { source: any };
}

const buttonItems: IButtonItems[] = [
  {
    navigate: "Open",
    text: "screens.home.new_open.open",
    imageId: {
      source: require("../../../assets/images/menu/open.png"),
    },
  },
  {
    navigate: "NewGrid",
    text: "screens.home.new_open.new",
    imageId: { source: require("../../../assets/images/menu/new.png") },
  },
  {
    navigate: "Learn",
    text: "screens.home.new_open.learn",
    imageId: { source: require("../../../assets/images/menu/learn.png") },
  },
];

const NewOpenControl = () => {
  const user = useContext<userContext>(UserContext);
  const navigation = useNavigation<RootStackNavigationProp>();

  const gotoPage = async (navigate: string | number) => {
    navigation.navigate(navigate as any);
  };

  const NewOpenButton: IButtonHeaderControl = {
    buttonItems: buttonItems,
    callback: gotoPage,
  };

  return (
    <View style={styles.header}>
     { user.Id && <ButtonHeaderControl {...NewOpenButton} />}
    </View>
  );
};

export default NewOpenControl;
