import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: "stretch",
    alignContent: "stretch",
    alignItems: "stretch",
    flexDirection: "row",
    marginHorizontal: 20,
    // padding: 5,
    // borderColor: "brown",
    // borderWidth: 1,
  },
  leftrigth: {
    flex: 1,
  },
  header:{
    alignContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    marginVertical: 10,
  },

  predefined: {
    flex: 1,
    backgroundColor: "red",
    width: "100%",
    height: "100%",
  },
  image: {
    // backgroundColor: 'yellow',
    // borderColor: 'blue',
    // borderWidth: 1,
    width: 100,
    height: 100,
  },
});
