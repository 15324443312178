import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  gridLine: {
    flexDirection: "row",
    alignContent: "stretch",
    justifyContent: "space-between",
    // backgroundColor: "green",

    padding: 12,
    borderColor: "lightgray",
    borderBottomWidth: 1,
  },
  iconlabel: {
    flexDirection: "row",
    // backgroundColor: "red",
    marginHorizontal: 5,
    alignSelf: "center",
  },

  label: {
    // backgroundColor: "white",
    marginHorizontal: 8,
  },
  iconLeft: {
    width: 38,
    height: 38,
    alignSelf: "center",
  },
  iconRight: {
    width: 32,
    height: 32,
    alignSelf: "center",
  },
  actionButtons: {
    flexDirection: "row",
    // backgroundColor: "lightblue",
    // alignSelf: "flex-end",
    alignSelf: "center",

    marginHorizontal: 8,
  },
  text_name: {
    color: "black",
    fontSize: 18,
  },
  text_date: {
    color: "gray",
    fontSize: 16,
  },
  // sectionUpper: {
  //   flex: 1,
  //   // alignItems: "flex-start",
  //   justifyContent: "center",
  //   // backgroundColor: "lightblue",
  //   marginTop: 32,
  //   minHeight: 100, // to avoid keyboad covering input - android
  // },
  // sectionLower: {
  //   flex: 4,
  // },
  // main: {
  //   flex: 1,
  //   // backgroundColor: "yellow",
  //   // alignItems: "stretch",
  //   // alignContent: "stretch",
  //   borderColor: "black",
  //   borderwidth: 5,

  // },
  textLineText: {
    // flex: 1,
    alignSelf: "center", // "flex-start",
    fontSize: 18,
    backgroundColor: "lightbrown",
    margin: 8,
  },
  textLine: {
    // flex: 1,
    marginHorizontal: 25,
    marginVertical: 8,
    borderWidth: 1,
    borderColor: "lightgray",

    flexDirection: "row",
    fontSize: 18,

    alignItems: "stretch",
    alignContent: "stretch",
    // justifyContent: "center",

    // backgroundColor: "lightgreen",
  },
  textLineInput: {
    flex: 1,
    // backgroundColor: "lightgray",
    fontSize: 18,
    // marginHorizontal:50,
    // marginVertical:10,
    // borderBottomWidth: 1,
  },
  image: {
    width: 32,
    height: 32,
    margin: 8,
  },
  imageIcon: {
    width: 24,
    height: 24,
    margin: 6,
  },
});
