import { useState } from "react";
import { View, Image } from "react-native";
import { GridProps } from "../GridProps";
import { styles } from "./Grid144.styles";

const Grid144 = (props: GridProps) => {
  function handleClick(cellId: number) {
    console.log("144 - Clicked : ", cellId);
  }

  function renderMainGrid() {
    return (
      <View style={styles.grid144}>
        <Image
          style={styles.image}
          source={require("../../../../assets/images/ll3comingsoon.png")}
        />

        {/* {[...Array(12)].map((item, i) => (
          // <View key={i}>
          // {/* <Text>{i}</Text> * /}
          <GridRow
            key={i}
            onClick={(x) => handleClick(x)}
            data={data}
            rowId={i}
            column={12}
          />
          // </View>
        ))} */}
      </View>
    );
  }

  return (
    <View>
      {renderMainGrid()}
      {/* <Text>144</Text> */}
    </View>
  );
};

export default Grid144;
