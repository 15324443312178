import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    // margin: 5,
    // padding: 5,
    // borderColor: "red",
    // borderWidth: 1,
  },
  contentLeft: {
    // flex: 1,
    alignItems: "center",
    justifyContent: "center",
    // borderColor: "green",
    // borderWidth: 1,
    // backgroundColor:"lightblue",
    padding: 5,
  },
  topSection: {
    // flex: 1,
    // borderBottomWidth: 1,
    // borderBottomColor: "black",
    // backgroundColor:"pink",
    padding: 5,

  },
  middleSection: {
    borderTopWidth: 2,
    borderTopColor: "black",
    width: "100%",
  },
  bottomSection: {
    // flex: 1,
    // justifyContent: "center",
    // alignItems: "center",
    // borderTopWidth: 1,
    // borderTopColor: "black",
    padding: 5,

  },

  contentCenter: {
    // flex: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 5, 
  },
  contentRight: {
    // flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    // borderColor: "orange",
    // borderWidth: 1,
    padding: 5,
  },
 
  // alignTop: {
  //   textAlignVertical: "top",
  // },
  // alignBottom: {
  //   textAlignVertical: "bottom",
  // },
  text: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    // backgroundColor: "yellow",
    // margin: 8,
  },
});
