import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";

import ShapeColorList from "../ShapeColorList/ShapeColorList";
import { styles } from "./PredefineShapes.styles";
import { WordsList } from "./WordsList";
import i18n from "../../../../Lang/translations";
import { GridItem } from "../GridItem/GridItem";

const convertShapeColorList = function (values: string): GridItem[] {
  let val = values.split(",");
  let newWordShapeColorList: GridItem[] = val.map((x: string, i: number) => {
    // need to support two cases here
    // 1. predefine words, that only have the shape value and color is being added according to the indes
    // 2. Sets & Stage - shape & color are pair

    let a = x.split(":");
    if (a.length === 2) {
      return { Shape: parseInt(a[0]), Color: parseInt(a[1]) };
    }
    //console.log("x: " + x + " i: " + i);
    return { Shape: parseInt(x), Color: 7 - i };
  });
  return newWordShapeColorList;
};

export const BuildWordGridItem = (
  values: string[][],
  prefix: string
): WordGridItem[] => {
  return values.map((x: string[], i: number) => {
    return {
      Name: x[0],
      DisplayName: i18n.t(prefix + "." + x[0] + ".Label"), // do the translation here@@@
      ShapeColorList: convertShapeColorList(x[1]),
    };
  });
};

export interface WordName {
  Name: string;
  DisplayName: string;
}
export interface WordGridItem extends WordName {
  ShapeColorList: GridItem[];
}

export interface PredefineShapesProps {
  wordlist: WordGridItem[];
  selectedWord: string;
  onClick: (
    selected: WordGridItem | null,
    final: boolean
  ) => void /*name: string, selectedGridItems: GridItem[]*/;
  showModal: boolean;
}

export const PredefineShapes = (props: PredefineShapesProps) => {
  const debug = false;

  function handleWordClick(selectedWord: string) {
    console.log("handleWordClick : ", selectedWord);
    // find the selected item in the array
    let index = GetWordList().findIndex((x) => x.Name === selectedWord);

    // notify parent on the selected word - while the user is still selecting
    props.onClick(props.wordlist[index], false);
  }

  function handleShapeColorClick() {
    // console.log("selectedWord ", SelectedWord);
    // popup the currently selected predefine shape
    // console.log(state.selected);
    let index = props.wordlist.findIndex((x) => x.Name === props.selectedWord);
    props.onClick(props.wordlist[index], true);
  }

  function GetWordList(): WordName[] {
    return props.wordlist.map((x: WordGridItem) => x); //{x.Name, x.DisplayName});
  }

  const handleClose = () => {
    props.onClick(null, true);
  };

  const renderMain = () => {
    return (
      <View
        style={[
          styles.Main,
          props.showModal && styles.MainBorder,
          debug && styles.MainDebug,
        ]}
      >
        <View style={[styles.WordList, debug && styles.WordListDebug]}>
          {debug && <Text>WordList</Text>}
          <WordsList
            onClick={handleWordClick}
            wordlist={GetWordList()}
            selectedWord={props.selectedWord}
          ></WordsList>
        </View>
        <View style={[styles.ShapeColor, debug && styles.ShapeColorDebug]}>
          {debug && <Text>ShapeColor</Text>}

          <ShapeColorList
            gridItem={{ Shape: 0, Color: 0 }}
            // gridItem={props.wordlist[props.wordlist.findIndex((x) => x.Name === props.selectedWord)]}
            // gridItemList={GetShapeColorForWord()}
            gridItemList={
              props.wordlist.findIndex((x) => x.Name === props.selectedWord) !==
              -1
                ? props.wordlist[
                    props.wordlist.findIndex(
                      (x) => x.Name === props.selectedWord
                    )
                  ].ShapeColorList
                : []
            }
            onClick={(value: GridItem) => handleShapeColorClick()}
            showDescription={false}
            readonly={!props.showModal}
            showSelected={false}
          />
        </View>
      </View>
    );
  };

  const renderArea = (key: keyof typeof styles) => {
    var keyDebug = (key + "Debug") as keyof typeof styles;
    return (
      <TouchableOpacity
        style={[styles[key] || {}, debug && styles[keyDebug]]}
        onPress={handleClose}
      >
        {debug && <Text>{key}</Text>}
      </TouchableOpacity>
    );
  };

  const renderModal = () => {
    return (
      <View style={styles.FullView}>
        {renderArea("Top")}
        <View style={[styles.Center, debug && styles.CenterDebug]}>
          {renderArea("Left")}
          {renderMain()}
          {renderArea("Right")}
        </View>
        {renderArea("Bottom")}
      </View>
    );
  };

  return (
    <View style={styles.FullView}>
      {props.showModal && renderModal()}
      {!props.showModal && renderMain()}
    </View>
  );
};
