// https://reactnavigation.org/docs/drawer-navigator/

import { createDrawerNavigator } from "@react-navigation/drawer";
import React, { useContext, useEffect, useRef } from "react";

import { useWindowDimensions, Image } from "react-native";
import {
  getData,
  UserContext,
  userContext,
  UserIdKey,
} from "../Context/userContext";

import AboutScreen from "../Screens/AboutScreen";
import GridScreen from "../Screens/GridScreen";
import HomeScreen from "../Screens/HomeScreen";
import LoginScreen from "../Screens/LoginScreen";
import LogoutScreen from "../Screens/LogoutScreen";
import NewGridScreen from "../Screens/NewGridScreen";
import OpenScreen from "../Screens/OpenScreen";
import RegisterScreen from "../Screens/RegisterScreen";
import LanguageScreen from "../Screens/LanguageScreen";

import { RootStackNavigationProp, RootStackParamList } from "./types";
import LoginLogoutButton from "./LoginLogoutButton";
import { useQuery } from "react-query";
import AccoutService from "../api/services/Account";
import { LoginResponse } from "../api/types/Accountypes";
import { useNavigation } from "@react-navigation/native";
import Languages from "../Lang/Languages";
import i18n from "../Lang/translations";
import * as SplashScreen from "expo-splash-screen";
import UpgradeScreen from "../Screens/UpgradeScreen";
import GlobalParams from "./../components/Globals/GlobalParams";
import LearnScreen from "../Screens/LearnScreen";
import UsersScreen from "../Screens/UsersScreen";

interface IScreenItem {
  name: any;
  component: any;
  image: { source: any };
  mode: "login" | "logout" | "all" | "hidden";
}
const ScreensItems: IScreenItem[] = [
  {
    name: "Home",
    component: HomeScreen,
    image: { source: require("../../assets/images/menu/home.png") },
    mode: "all",
  },
  {
    name: "NewGrid",
    component: NewGridScreen,
    image: { source: require("../../assets/images/menu/new.png") },
    mode: "login",
  },
  {
    name: "Open",
    component: OpenScreen,
    image: { source: require("../../assets/images/menu/open.png") },
    mode: "login",
  },

  {
    name: "Grid",
    component: GridScreen,
    image: { source: require("../../assets/images/menu/new.png") },
    mode: "hidden",
  },
  {
    name: "Upgrade",
    component: UpgradeScreen,
    image: { source: require("../../assets/images/menu/new.png") },
    mode: "hidden",
  },
  {
    name: "Register",
    component: RegisterScreen,
    image: { source: require("../../assets/images/menu/register.png") },
    mode: "logout",
  },
  {
    name: "Login",
    component: LoginScreen,
    image: { source: require("../../assets/images/menu/login.png") },
    mode: "logout",
  },
  {
    name: "Logout",
    component: LogoutScreen,
    image: { source: require("../../assets/images/menu/logout.png") },
    mode: "hidden",
  },
  {
    name: "Learn",
    component: LearnScreen,
    image: { source: require("../../assets/images/menu/learn.png") },
    mode: "all",
  },
  {
    name: "Language",
    component: LanguageScreen,
    image: { source: require("../../assets/images/menu/lang.png") },
    mode: "all",
  },
  {
    name: "About",
    component: AboutScreen,
    image: { source: require("../../assets/images/menu/about.png") },
    mode: "all",
  },
  {
    name: "Users",
    component: UsersScreen,
    image: { source: require("../../assets/images/menu/about.png") },
    mode: "hidden",
  },
];

const Drawer = createDrawerNavigator<RootStackParamList>();

export function MainDrawer() {
  const navigation = useNavigation<RootStackNavigationProp>();

  const id = useRef("");
  const user = useContext<userContext>(UserContext);

  const dimensions = useWindowDimensions();
  GlobalParams.SetLargeScreen(dimensions.width >= 768);

  useEffect(() => {
    Languages.LoadDefaultLanguage(user);
    // start relogin
    const dataObj = getData(UserIdKey);
    dataObj.then((data) => {
      console.log("userId (storeData):", data);
      if (data !== null) {
        id.current = data as string;
        sendRelogin();
      } else {
        console.log("no userId (storeData): [open]", data);
        setStartPage("Login");
      }
    });
  }, []); // empty [] - means only run on first

  useEffect(() => {
    // this is viewOnly, switch to grid
    if (user.ViewOnlyByExternalId) {
      navigation.navigate("Grid");
    }
  }, [user.ViewOnlyByExternalId]); // empty [] - means only run on first

  const { refetch: sendRelogin } = useQuery<LoginResponse, Error>(
    "query-relogin",
    async () => {
      console.log("AccoutService.relogin:", id.current);
      return await AccoutService.relogin(id.current);
    },
    {
      enabled: false,
      retry: 0,
      onSuccess: (res) => {
        ReloginResponse(res);
      },
      onError: (err: any) => {
        ReloginError(err);
      },
    }
  );

  const setStartPage = (page: any) => {
    //navigation.navigate("User");
    // navigation.navigate("Language");
    // navigation.navigate("Learn");
    navigation.navigate(page);
    SplashScreen.hideAsync();
  };

  const ReloginResponse = (login: LoginResponse): void => {
    //console.log("RELogin Response", user);
    // for some unknown reason - on edge
    // the login is being send eventhough there is external id
    // so, ignore the relogin response on viewonlygrid mode
    if (user.ViewOnlyByExternalId) {
      return;
    }

    AccoutService.HandleLoginResponse(login, user);
    if (login.Upgrade) {
      setStartPage("Upgrade");
    } else {
      setStartPage("Home");
    }
  };

  const ReloginError = (err: any): void => {
    console.log("Login Error");
  };

  const DrawerScreen = (item: IScreenItem) => {
    return (
      <Drawer.Screen
        key={item.name}
        name={item.name}
        component={item.component}
        options={{
          title: i18n.t("drawer." + item.name.toLowerCase() + "_title"),
          drawerLabel: i18n.t(
            "drawer." + item.name.toLowerCase() + "_menu_label"
          ),
          drawerIcon: () => (
            <Image
              {...item.image}
              fadeDuration={0}
              style={{ width: 32, height: 32 }}
            />
          ),
          drawerItemStyle: [
            item.mode === "hidden" ||
            (item.mode === "login" && !user.Id) ||
            (item.mode === "logout" && user.Id)
              ? { display: "none" }
              : {},
          ],
          // special case for viewOnly (will have effect only on the grid screen)
          headerShown: !user.ViewOnlyByExternalId,
        }}
      />
    );
  };

  return (
    <Drawer.Navigator
      // initialRouteName="Upgrade"
      // drawerContent={(props: DrawerContentComponentProps) => (
      //   <CustomDrawerContent {...props} />
      // )}
      // screenOptions={{ headerShown: true }}
      // screenOptions={{ drawerPosition: Local.RTL ? "right" : "left" }}
      backBehavior="history"
      screenOptions={{
        // drawerStyle: isLargeScreen ? null : { width: '100%' },
        // overlayColor: 'transparent',

        headerRight: () => {
          return <LoginLogoutButton />;
        },
      }}
    >
      {ScreensItems.map((item) => DrawerScreen(item))}
    </Drawer.Navigator>
  );
}
