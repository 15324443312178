import React, { useState } from "react";
import { Alert, Modal, View, Platform } from "react-native";
import GlobalParams from "../../Globals/GlobalParams";

import { styles } from "./Grid49.styles";
import GridRow from "../GridRow/GridRow";
import {
  BuildWordGridItem,
  PredefineShapes,
  WordGridItem,
} from "../Core/PredefineShapes/PredefineShapes";
import ShapeColorSelection from "../Core/ShapeColorSelection/ShapeColorSelection";
import { Grid49Enum } from "./SetStageEnum";

import { ReactNativeZoomableView } from "@openspacelabs/react-native-zoomable-view";
import { GridType } from "../../../api/types/GridsTypes";
import { GridProps } from "../GridProps";
import { GridItem } from "../Core/GridItem/GridItem";

enum ModalState {
  Close,
  Stage,
  Set,
  ColorShape,
}

const Grid49 = (props: GridProps) => {
  const [modalMode, setModalMode] = useState<ModalState>(ModalState.Close);

  const [CurrentCellId, setCurrentCellId] = useState(0);
  const [selectedSet, setSelectedSet] = useState<string>("");
  const [selectedStage, setSelectedStage] = useState<string>("");

  const [savedData, setSavedData] = useState<GridItem[]>([]);
  const [savedSelectedSet, setSavedSelectedSet] = useState<string>("");
  const [savedSelectedStage, setSavedSelectedStage] = useState<string>("");

  function handleClick(cellId: number) {
    console.log("Grid49: (cellId)" + cellId);
    setCurrentCellId(cellId);

    let col = cellId % 7;
    let row = Math.floor(cellId / 7);
    console.log("Grid49: col:" + col + " row:" + row);

    setSavedData(props.data.slice());
    setSavedSelectedSet(selectedSet);
    setSavedSelectedStage(selectedStage);

    switch (col) {
      case 0:
        setModalMode(ModalState.Set);
        break;
      case 6:
        setModalMode(ModalState.Set);
        break;

      case 1:
        setModalMode(ModalState.Stage);
        break;
      case 5:
        setModalMode(ModalState.Stage);
        break;

      default:
        console.log("default");
        setModalMode(ModalState.ColorShape);
        break;
    }
  }
  function ShapeColorSelected(gridItem: GridItem): void {
    console.log("Grid49=> cellId:" + CurrentCellId + ",  GridItem: ", gridItem);

    if (
      props.data[CurrentCellId].Shape !== gridItem.Shape ||
      props.data[CurrentCellId].Color !== gridItem.Color
    ) {
      let newData = props.data.slice();
      newData[CurrentCellId] = gridItem;

      // handle special cases (3x3 - 5x5 , 3x5 - 5x3)
      switch (CurrentCellId) {
        case 16:
          newData[32] = gridItem;
          break;
        case 32:
          newData[16] = gridItem;
          break;
        case 18:
          newData[30] = gridItem;
          break;
        case 30:
          newData[18] = gridItem;
          break;
        default: // do nothing
          break;
      }

      props.setNewData(newData, true);
    }
    setModalMode(ModalState.Close);
  }

  function SetStagesSelected(
    selectedItem: WordGridItem,
    colA: number,
    colB: number,
    save: boolean
  ) {
    let newData = props.data.slice();
    for (let i = 0; i < 7; i++) {
      newData[7 * i + colA] = newData[7 * i + colB] =
        selectedItem.ShapeColorList[i];
    }
    props.setNewData(newData, save);
  }

  function SetSelected(selectedItem: WordGridItem | null, final: boolean) {
    console.log("Grid49: SetSelected: ", selectedItem);
    if (selectedItem) {
      SetStagesSelected(selectedItem, 0, 6, final);
      console.log("Grid49: SetSelected: " + selectedItem.Name);
      setSelectedSet(selectedItem.Name);
    }
    if (final) {
      setModalMode(ModalState.Close);
      if (selectedItem === null) {
        console.log("Grid49: before savedData: ", savedData);
        props.setNewData(savedData, false);
        console.log("Grid49: after savedData: ", savedData);

        if (savedSelectedSet !== selectedSet) {
          setSelectedSet(savedSelectedSet);
        }
      }
    }
  }

  function StagesSelected(selectedItem: WordGridItem | null, final: boolean) {
    console.log("Grid49: StagesSelected: ", selectedItem);
    if (selectedItem) {
      SetStagesSelected(selectedItem, 1, 5, final);
      console.log("Grid49: StagesSelected: " + selectedItem.Name);
      setSelectedStage(selectedItem.Name);
    }
    if (final) {
      setModalMode(ModalState.Close);
      if (selectedItem === null) {
        props.setNewData(savedData, false);
        if (savedSelectedStage !== selectedStage) {
          setSelectedStage(savedSelectedStage);
        }
      }
    }
  }

  function renderMainGrid() {
    return (
      <View style={styles.grid49}>
        {[...Array(7)].map((item, i) => (
          // <View key={i}>
          // {/* <Text>{i}</Text> */}
          <GridRow
            key={i}
            onClick={(x) => handleClick(x)}
            data={props.data}
            rowId={i}
            column={7}
            readonly={props.readonly}
          />
          // </View>
        ))}
      </View>
    );
  }

  return (
    <View>
      <Modal
        animationType="fade"
        transparent={false}
        visible={modalMode !== ModalState.Close}
        onRequestClose={() => {
          // Alert.alert("Modal has been closed.");
          setModalMode(ModalState.Close);
        }}
      >
        {modalMode === ModalState.ColorShape && (
          <ShapeColorSelection
            onClick={(value: GridItem) => ShapeColorSelected(value)}
            gridItem={props.data[CurrentCellId]}
            level={GridType.G49}
          />
        )}
        {modalMode === ModalState.Set && (
          <PredefineShapes
            wordlist={BuildWordGridItem(Grid49Enum.Sets, "Sets")}
            selectedWord={selectedSet}
            onClick={(item, final) => {
              SetSelected(item, final);
            }}
            showModal={true}
          />
        )}
        {modalMode === ModalState.Stage && (
          <PredefineShapes
            wordlist={BuildWordGridItem(Grid49Enum.Stages, "Stages")}
            selectedWord={selectedStage}
            onClick={(item, final) => {
              StagesSelected(item, final);
            }}
            showModal={true}
          />
        )}
      </Modal>
      {/* only render the zoom - pan on device - not on wed, on web let the browser do the work */}
      {/* {Platform.OS !== "web" && renderMainGrid()} */}
      {Platform.OS !== "web" && (
        <ReactNativeZoomableView
          maxZoom={1.5}
          minZoom={0.5}
          zoomStep={0.5}
          initialZoom={0.65}
          bindToBorders={false}
          // onZoomAfter={this.logOutZoomState}
          // style={{
          //   padding: 10,
          //   backgroundColor: "red",
          // }}
        >
          {renderMainGrid()}
        </ReactNativeZoomableView>
      )}

      {Platform.OS === "web" && renderMainGrid()}
    </View>
  );
};

export default Grid49;
