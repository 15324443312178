import React, { useContext, useState } from "react";
import {
  GridItem,
  GridItem_Undefined,
} from "../../components/Grids/Core/GridItem/GridItem";
import { UserContext, userContext } from "../../Context/userContext";
import { LightLanguageEnum } from "../../components/Grids/Core/GridItem/EnumValues";
import LearnColorsShapes from "./LearnColorsShapes";

export interface ILearnShape {
  onClick: (gridItem: GridItem) => void;
}

const LearnShape = (props: ILearnShape) => {
  const user = useContext<userContext>(UserContext);

  let maxColor = LightLanguageEnum.Shapes.length - 1;

  let ShapeData: GridItem[] = LightLanguageEnum.Colors.slice(1, maxColor).map(
    (x: string, i: number) => {
      return { Shape: i + 1, Color: GridItem_Undefined };
    }
  );

  return (
    <LearnColorsShapes
      data={ShapeData}
      onClick={props.onClick}
      readonly={false}
    />
  );
};

export default LearnShape;
