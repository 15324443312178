import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useRef, useState } from "react";
import { View, Text, TextInput, Image, TouchableOpacity } from "react-native";
import { useQuery } from "react-query";
import AccoutService from "../api/services/Account";
import { AccountErrors, LoginResponse } from "../api/types/Accountypes";
import { UserContext, userContext } from "../Context/userContext";
import i18n from "../Lang/translations";
import { RootStackNavigationProp } from "../Navigation/types";
import LL_Header from "./HeaderComponents/LL_Header";
import { styles } from "./RegisterScreen.styles";

const LoginScreen = () => {
  const user = useContext<userContext>(UserContext);
  const navigation = useNavigation<RootStackNavigationProp>();

  const [name, onChangeName] = useState("");
  const [teacher, onChangeTeacher] = useState("");
  const [registerEnabled, setRegisterEnabled] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const timer = useRef<NodeJS.Timer>();

  useEffect(() => {
    // console.log( "register: " + name + " " + teacher + " " + email + " " + password + ":"+registerEnabled + " :: " + (email !== "" && password?.length >= 4 && name?.length > 1) );
    setRegisterEnabled(
      email?.length >= 4 && password?.length >= 4 && name?.length > 1
    );
  }, [email, password, name]);

  const { isLoading: isLoadingTutorials, refetch: sendRegister } = useQuery<
    LoginResponse,
    Error
  >(
    "query-register",
    async () => {
      return await AccoutService.register({
        Name: name.trim(),
        Teacher: teacher.trim(),
        Password: password.trim(),
        Email: email.trim(),
      });
    },
    {
      enabled: false,
      retry: 0,
      onSuccess: (res) => {
        RegisterSuccess(res);
      },
      onError: (err: any) => {
        RegisterError(err);
      },
    }
  );

  const setError = (err: string) => {
    setErrorText(err);

    timer.current = setInterval(() => {
      clearError();
    }, 5000);
  };

  const clearError = () => {
    setErrorText("");
    clearInterval(timer.current);
  };

  const RegisterSuccess = (login: LoginResponse): void => {
    if (AccoutService.HandleLoginResponse(login, user) === AccountErrors.OK) {
      navigation.navigate("Home");
    } else {
      setError(i18n.t("screens.register.reg_error"));
    }
  };

  const RegisterError = (err: any): void => {
    console.log("Login Error");
  };

  const DoRegister = () => {
    console.log(
      "register: " + name + " " + teacher + " " + email + " " + password
    );

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;

    if (!email.match(validRegex)) {
      setError(i18n.t("screens.register.email_error"));
      return;
    }

    clearError();
    try {
      sendRegister();
    } catch (error) {}
  };

  return (
    <View style={styles.container}>
      <LL_Header />

      <View style={styles.sectionMain}>
        <View style={styles.textLine}>
          <Image
            style={styles.imageIcon}
            source={require("../../assets/images/login/name.png")}
          />
          <TextInput
            style={styles.textLineInput}
            placeholder={i18n.t("screens.register.yourfullname")}
            onChangeText={(name) => onChangeName(name)}
            value={name}
          />
        </View>
        <View style={styles.textLine}>
          <Image
            style={styles.imageIcon}
            source={require("../../assets/images/login/teacher.png")}
          />
          <TextInput
            style={styles.textLineInput}
            placeholder={i18n.t("screens.register.yourteachername")}
            onChangeText={(teacher) => onChangeTeacher(teacher)}
            value={teacher}
          />
        </View>
        <View style={styles.textLine}>
          <Image
            style={styles.imageIcon}
            source={require("../../assets/images/login/email.png")}
          />
          <TextInput
            style={styles.textLineInput}
            placeholder={i18n.t("screens.register.youremail")}
            onChangeText={(email) => setEmail(email)}
            value={email}
          />
        </View>
        <View style={styles.textLine}>
          <Image
            style={styles.imageIcon}
            source={require("../../assets/images/login/pass.png")}
          />
          <TextInput
            style={styles.textLineInput}
            secureTextEntry={true}
            placeholder={i18n.t("screens.register.yourpassword")}
            onChangeText={(password) => setPassword(password)}
            value={password}
          />
        </View>
        <Text style={[styles.textLineText, styles.textLineTextError]}>
          {errorText}
        </Text>
      </View>

      <View style={styles.footer}>
        <TouchableOpacity
          onPress={() => DoRegister()}
          disabled={!registerEnabled}
        >
          <Text style={styles.textLineText}>
            {i18n.t("screens.register.register")}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LoginScreen;
