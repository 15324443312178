import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    // alignItems: "stretch",
    // alignContent: "stretch",
    flexDirection: "column",
  },
  button: {
    justifyContent: "center",
    // alignItems:"flex-start",
    alignItems: "center",
    borderColor: "darkgray",
    borderWidth: 4,
    borderRadius: 10,
    padding: 10,
    backgroundColor:"aqua",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
  },
  image: {
    margin: 10,
    width: 144,
    height: 144,
  },
});
