import "react-native-gesture-handler";

import { useEffect, useState } from "react";
import { RootNavigator } from "./src/Navigation";
import { UserContext, userContext } from "./src/Context/userContext";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Linking from "expo-linking";
import GlobalParams from "./src/components/Globals/GlobalParams";
import { Platform } from "react-native";
import Languages from "./src/Lang/Languages";
import * as SplashScreen from 'expo-splash-screen';

SplashScreen.preventAutoHideAsync();


const queryClient = new QueryClient();

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//       refetchOnmount: false,
//       refetchOnReconnect: false,
//       retry: false,
//       staleTime: 5*60*1000,
//     },
//   },
// });
const handleViewGridMode = (url: string | null, user: userContext) => {
  if (url) {
    const { hostname, path, queryParams } = Linking.parse(url);

    // old mode - showgrid
    // http://localhost:19006/showgrid?id=142fa35b-b55e-4229-a70b-479e6ab95408
    if (url.search("showgrid") > -1) {
      const idParts = url.split("=");
      if (idParts?.length === 2) {
        setExternalId(user, idParts[1]);
        return;
      }
    }


    // new mode - gridview
    // http://localhost:19006/gridview/142fa35b-b55e-4229-a70b-479e6ab95408
    const pathParts = path?.split("/");
    // console.log("pathParts:", pathParts);
    if (pathParts?.length === 2) {
      if (pathParts[0] === GlobalParams.GetGridViewPrefix()) {
        setExternalId(user, pathParts[1]);
      }
    }
  }
};


function setExternalId(user: userContext, id: string) {
  user.setUserContext({
    ...user,
    ViewOnlyByExternalId: id,
  });
}

export default function App() {
  const setUserFunc = (newUser: userContext) => {
    setUser(newUser);
  };

  const [user, setUser] = useState<userContext>({
    Language: Languages.Language.en, // set default language
    setUserContext: setUserFunc,
  } as userContext);

  const url = Linking.useURL();

  useEffect(() => {
    if (Platform.OS === "web") {
      console.log("useEffect-pathParts:", url);
      handleViewGridMode(url, user);
    }
  }, [url]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={user}>
        <RootNavigator />
      </UserContext.Provider>
    </QueryClientProvider>
  );
}
