import axios from "axios";
import GlobalParams from "../../components/Globals/GlobalParams";
import Logs from "../../components/Globals/Logs";
import { GridData } from "../types/GridData";

var GridsURL = GlobalParams.GetServerAPIURL() + "Grids/";

const List = async (userId: string): Promise<GridData[]> => {
  if (Logs.GridsLogs) console.log("listGrids: ", userId);

  const data = await axios
    .get(GridsURL + "List?userId=" + userId)
    .then((response) => {
      if (Logs.GridsLogs) console.log(response.data);
      // console.log(response.status);
      // console.log(response.statusText);
      // console.log(response.headers);
      // console.log(response.config);
      return response.data;
    });

  return data;
};

const Load = async (userId: string, gridId: number): Promise<GridData> => {
  if (Logs.GridsLogs) console.log("loadGrid: user:", userId, " grid:", gridId);

  if (gridId === 0) {
    console.log("loadGrid: new grid === 0");
    return {} as GridData;
  }
  const gridItemData = await axios
    .get(GridsURL + "Load?userId=" + userId + "&id=" + gridId)
    .then((response) => {
      if (Logs.GridsLogs) console.log(response.data);

      return response.data;
    });
  return gridItemData;
};

const LoadByExternalId = async (exterbalId: string): Promise<GridData> => {
  console.log("loadGrid: exterbalId:", exterbalId);

  if (exterbalId === "") {
    if (Logs.GridsLogs) console.log("loadGrid: exterbalId === ''");
    return {} as GridData;
  }
  const gridItemData = await axios
    .get(GridsURL + "LoadByExternalId?externalId=" + exterbalId)
    .then((response) => {
      if (Logs.GridsLogs) console.log(response.data);

      return response.data;
    });
  return gridItemData;
};

const Save = async (userId: string, item: GridData): Promise<GridData> => {
  if (Logs.GridsLogs) console.log("saveGrid: ", item);

  const data = await axios
    // .post(GridsURL + userId + "&id=" + item.Id, item)
    .post(GridsURL + "Save?userId=" + userId, item)
    .then((response) => {
      if (Logs.GridsLogs) console.log(response);

      return response.data;
    });
  return data;
};

const Delete = async (userId: string, gridId: number): Promise<GridData> => {
  if (Logs.GridsLogs)
    console.log("deleteGrid: user:", userId, " grid:", gridId);

  if (gridId === 0) {
    if (Logs.GridsLogs) console.log("deleteGrid: new grid === 0");
    return {} as GridData;
  }
  const gridItemData = await axios
    .delete(GridsURL + "delete?userId=" + userId + "&id=" + gridId)
    .then((response) => {
      console.log(response.data);
      return response.data;
    });
  return gridItemData;
};

const Duplicate = async (
  userId: string,
  gridId: number,
  newName: string
): Promise<GridData> => {
  if (Logs.GridsLogs)
    console.log(
      "duplicateGrid: user:",
      userId,
      " grid:",
      gridId,
      " newName:",
      newName
    );

  if (gridId === 0) {
    if (Logs.GridsLogs) console.log("duplicateGrid: new grid === 0");
    return {} as GridData;
  }
  const gridItemData = await Load(userId, gridId);
  if (Logs.GridsLogs) console.log("duplicateGrid: get grid:", gridItemData);

  gridItemData.Id = 0;
  gridItemData.Name = newName;
  // gridItemData.UserId = userId;

  const saveItemData = await Save(userId, gridItemData);
  if (Logs.GridsLogs) console.log("duplicateGrid: post response", saveItemData);

  // const gridItemData = await axios
  //   .delete(GridsURL + userId + "&id=" + gridId)
  //   .then((response) => {
  //     console.log(response.data);
  //     return response.data;
  //   });
  return saveItemData;
};

const GridService = {
  List,
  Load,
  LoadByExternalId,
  Save,
  Delete,
  Duplicate,
};

export default GridService;
