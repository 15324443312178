import React, { useState } from "react";
import { View, Text } from "react-native";
import { styles } from "./LearnFormula.styles";
import i18n from "../../Lang/translations";
import { LightLanguageEnum } from "../../components/Grids/Core/GridItem/EnumValues";

interface ILearnFormualData {
  Top: string;
  Bottom: string;
  Eq: string;
}

interface ILearnFormula {
  shape: number;
}

const LearnFormula = (props: ILearnFormula) => {
  // const [data, setData] = useState<ILearnFormualData>(GetShapeFormula(props.shape));

  function GetShapeFormula(shape: number): ILearnFormualData | undefined {
    let d: ILearnFormualData | string = i18n.t(
      "Shapes." + LightLanguageEnum.Shapes[shape] + ".Formula"
    );

    // translation value is missing, valie scenario
    if (typeof d === "string") {
      return undefined;
    }

    // console.log(typeof(d));
    // console.log(d);

    return d as ILearnFormualData;
  }

  let data: ILearnFormualData | undefined = GetShapeFormula(props.shape);

  const showFormula = (data: ILearnFormualData) => {
    return (
      <View style={styles.container}>
        <View style={styles.contentLeft}>
          <View style={styles.topSection}>
            <Text style={styles.text}>{data.Top}</Text>
          </View>
          <View style={styles.middleSection}>
          </View>
          
          <View style={styles.bottomSection}>
            <Text style={styles.text}>{data.Bottom}</Text>
          </View>
        </View>
        <View style={styles.contentCenter}>
          <Text style={styles.text}>=</Text>
        </View>
        <View style={styles.contentRight}>
          <Text style={styles.text}>{data.Eq}</Text>
        </View>
      </View>
    );
  };
  return <View>{data !== undefined && showFormula(data)}</View>;
};
export default LearnFormula;
