import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  image: {
    width: 24,
    height: 24,
  },
  login_register: {
    // flex: 1,
    // top: 0,
    // right: 0,
    // left: 0,
    // position: "absolute",
    flexDirection: "row",
    // alignSelf: "stretch",
    justifyContent: "space-evenly",
    alignSelf: "center", // "flex-start",

    // alignItems: ""
    // alignContent: "stretch",
    // alignContent:"stretch",
    // backgroundColor: "lightblue",
    marginRight: 10,
  },
  textLineText: {
    // flex: 1,
    alignSelf: "center", // "flex-start",
    // fontSize: 28,
    // backgroundColor: "lightbrown",
    // margin: 10,
  },
  
});
