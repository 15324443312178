import { useContext, useEffect, useState } from "react";
import { UserContext, userContext } from "../Context/userContext";
import { View, Text, TouchableOpacity, FlatList, Image, TextInput } from "react-native";
import { styles } from "./UsersScreen.style";
import { IUser } from "../api/types/Accountypes";
import AccoutService from "../api/services/Account";
import { useQuery } from "react-query";

const FormatDate = (str: string) => {
    return str.split("T")[0];
  };

const UsersScreen = () => {
  const user = useContext<userContext>(UserContext);
  const [users, setUsers] = useState<IUser>();

  const [filterText, onChangeFilterText] = useState("");
  const [filterGridItem, setFilterGridItem] = useState<IUser[] | []>([]);

  const {
    isLoading,
    status,
    data,
    refetch: reloadGetUsers,
  } = useQuery<IUser[], string>(
    ["getUsers"], // query key
    () => AccoutService.getUsers(), // fetching function
    {
      enabled: true,
      retry: 0,
    }
  );

  const getUsers = () => {
    console.log("getUsers");
    reloadGetUsers();
    // AccoutService.getUsers();
  };

  useEffect(() => {
    // console.log("filterGridItem:", filterText);
    if (data) {
      const d: IUser[] = data.filter((item) =>
        item.UserName!.toLowerCase().includes(filterText.toLowerCase()) ||
        item.Email!.toLowerCase().includes(filterText.toLowerCase())
      );
      d.sort((a, b) => {
        return a.Created! < b.Created! ? 1 : -1;
      });
      setFilterGridItem(d);
    }
  }, [filterText, data]);

  const gridIconLabel = (item: IUser) => {
    return (
      // <View style={styles.iconlabel}>
      <TouchableOpacity
        style={styles.iconlabel}
        onPress={() => {} /*openGrid(item)*/}
      >
        {/* <Image style={styles.iconLeft} source={GetIcon(item.GridType!)} /> */}
        {gridLabel(item)}
      </TouchableOpacity>
      // </View>
    );
  };
  const gridLabel = (item: IUser) => {
    return (
      <View style={styles.label}>
        <Text style={styles.text_name}>{item.UserName}        [{item.Teacher}]</Text>
        {/* <Text style={styles.text_name}>{item.Teacher}</Text> */}
        <Text style={styles.text_name}>{item.Email}       [{FormatDate(item.Created)}]</Text>
        {/* <Text style={styles.text_date}>{FormatDate(item.Updated!)}</Text> */}
      </View>
    );
  };

  const filterHeader = () => {
    return (
      <View style={styles.textLine}>
        <TouchableOpacity onPress={() => onChangeFilterText("")}>
          <Image
            source={require("../../assets/images/menu/search.png")}
            style={styles.image}
          />
        </TouchableOpacity>
        <TextInput
          style={styles.textLineInput}
          onChangeText={(filterText) => onChangeFilterText(filterText)}
          value={filterText}
        />
      </View>
    );
  };
  const gridActionButtons = (item: IUser) => {
    return (
      <View style={[] /*styles.actionButtons*/}>
        {/* <TouchableOpacity
          style={styles.iconlabel}
          onPress={() => shareGrid(item)}
        >
          <Image
            style={styles.iconRight}
            source={require("../../assets/images/menu/share.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.iconlabel}
          onPress={() => {
            setSelectedActionGrid(item);
            setShowDuplicateDialog(true);
          }}
        >
          <Image
            style={styles.iconRight}
            source={require("../../assets/images/menu/copy.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.iconlabel}
          onPress={() => {
            setSelectedActionGrid(item);
            setShowDeleteDialog(true);
          }}
        >
          <Image
            style={styles.iconRight}
            source={require("../../assets/images/menu/delete.png")}
          />
        </TouchableOpacity> */}
      </View>
    );
  };

  const gridLine = (item: IUser) => {
    return (
      // <View style={styles.gridLine}>
      <TouchableOpacity
        style={styles.gridLine}
        onPress={() => {} /*openGrid(item)*/}
      >
        {gridIconLabel(item)}
        {/* {gridActionButtons(item)} */}
      </TouchableOpacity>
      // {/* </View> */}
    );
  };

  const gridsItems = (userdata: IUser[]) => {
    return (
      <FlatList
        data={userdata}
        renderItem={({ item }) => gridLine(item)}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  };
  const emptyList = () => {
    return (
      <Text style={styles.textLineText}>
        Grid list empty, Create grid first
      </Text>
    );
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={getUsers}>
        <Text>Users</Text>
      </TouchableOpacity>
      {filterHeader()}

      {data && data.length > 0
        ? gridsItems(filterGridItem)
        : !isLoading && emptyList()}
    </View>
  );
};

export default UsersScreen;
