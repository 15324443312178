import { useContext } from "react";
import { View, Image, Text, Pressable } from "react-native";
import { UserContext, userContext } from "../../Context/userContext";
import { GridItem } from "../../components/Grids/Core/GridItem/GridItem";
import ShapeColorList from "../../components/Grids/Core/ShapeColorList/ShapeColorList";
import { styles } from "./LearnColorsShapes.styles";
import GlobalParams from "../../components/Globals/GlobalParams";

export interface ILearnColorsShapes {
  data: GridItem[];
  onClick: (gridItem: GridItem) => void;
  readonly: boolean;
}

const LearnColorsShapes = (props: ILearnColorsShapes) => {
  const user = useContext<userContext>(UserContext);

  return (
    <View style={styles.container}>
      <View style={styles.Left}></View>
      <View
        style={[GlobalParams.IsLargeScreen() ? styles.MainLarge : styles.Main]}
      >
        <ShapeColorList
          gridItem={props.data[0]}
          gridItemList={props.data}
          onClick={(value: GridItem) => props.onClick(value)}
          showDescription={true}
          readonly={props.readonly}
          showSelected={false}
        />
      </View>
      <View style={styles.Right}></View>
    </View>
  );
};

export default LearnColorsShapes;
