import { getData, storeData, userContext } from "../Context/userContext";
import i18n from "./translations";

const DefaultLanguageKey = "Lang";

export enum Language {
  en = "en",
  he = "he",
  es = "es",
  hr = "hr", // croatian
  Other = "00",
}

// enum Language {
//   en,
//   he,
//   es,
//   Other,
// }
export interface ILangItem {
  lang: Language;
  name: string;
  image: {}; // source: string };
  imageSmall: {}; // source: string };
}
const LangItems: ILangItem[] = [
  {
    lang: Language.en,
    name: "English",
    image: { source: require("../../assets/images/lang/en.png") },
    imageSmall: { source: require("../../assets/images/lang/small/en.png") },
  },
  {
    lang: Language.he,
    name: "עברית",
    image: { source: require("../../assets/images/lang/he.png") },
    imageSmall: { source: require("../../assets/images/lang/small/he.png") },
  },
    {
      lang: Language.es,
      name: "español",
      image: { source: require("../../assets/images/lang/es.png") },
      imageSmall: { source: require("../../assets/images/lang/small/es.png") },
    },
      {
      lang: Language.hr,
      name: "Hrvatski", // croatian
      image: { source: require("../../assets/images/lang/hr.png") },
      imageSmall: { source: require("../../assets/images/lang/small/hr.png") },
    },
  {
    lang: Language.Other,
    name: "Other",
    image: { source: require("../../assets/images/lang/language.png") },
    imageSmall: { source: require("../../assets/images/lang/small/es.png") },
  },
];

const GetLanguage = (lang: Language): ILangItem => {
  const langItem = LangItems.find((l) => l.lang === lang);
  return langItem ? langItem : LangItems[0];
};

const SetDefaultLanguage = (user: userContext, lang: Language) => {
  user.setUserContext({ ...user, Language: lang });
  i18n.locale = lang;
  storeData(DefaultLanguageKey, lang);
};
const LoadDefaultLanguage = (user: userContext) => {
  getData(DefaultLanguageKey).then((lang) => {
    const l = lang ? (lang as Language) : Language.en;
    user.setUserContext({ ...user, Language: l });
    i18n.locale = l;
  });
};

const Languages = {
  LangItems,
  Language,
  SetDefaultLanguage,
  LoadDefaultLanguage,

  GetLanguage,
};

export default Languages;
