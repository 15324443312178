import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  grid49: {
    flex: 1,
    flexDirection:"column",
    // backgroundColor: 'lime',
    // borderColor: 'black',
    // borderWidth: 2,
    // width: "60%",
    // height: "60%",
    // justifyContent: "flex-start",
    justifyContent: "center",
    // alignItems: "center",
  },

});
