import React from "react";

import ShapeColorText from "../ShapeColorText/ShapeColorText";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import { styles } from "./ShapeColorList.styles";
import { GetGridItemDescription, GridItem } from "../GridItem/GridItem";

export interface ShapeColorListProps {
  gridItem: GridItem;
  onClick: (gridItem: GridItem) => void; //(index: number) => void,
  gridItemList: GridItem[];
  showDescription: boolean;
  readonly: boolean;
  showSelected: boolean;
}

const ShapeColorList = (props: ShapeColorListProps) => {
  function handleClick(item: GridItem) {
    props.onClick(item);
  }

  const renderMainGrid = (item: GridItem) => {
    return (
      <ShapeColorText
        gridItem={item}
        onClick={() => handleClick(item)}
        readonly={props.readonly}
        seleted={
          props.showSelected &&
          (props.gridItem.Shape === item.Shape ||
            props.gridItem.Color === item.Color)
        }
      />
    );
  };

  return (
    <View style={styles.ShapeColorContainer}>
      <FlatList
        data={props.gridItemList}
        keyExtractor={(item, index) => index.toString()}
        listKey={"ShapeColorList"}
        renderItem={({ item }) =>
          props.showDescription === true ? (
            <TouchableOpacity
              style={styles.v}
              // key={i.toString()}
              onPress={() => handleClick(item)}
              disabled={props.readonly}
            >
              {renderMainGrid(item)}

              <Text style={styles.description}>
                {GetGridItemDescription(item)}
              </Text>
            </TouchableOpacity>
          ) : (
            renderMainGrid(item)
          )
        }
      />
    </View>
  );
};

export default ShapeColorList;
