import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  grid7: {
    flex: 1,
    // flexDirection:"row",
    // backgroundColor: 'skyblue',
    // borderColor: 'black',
    // borderWidth: 1,
    width: "100%",
    height: "100%",
    // justifyContent: "flex-start",
    // justifyContent: "center",
    alignItems: "center",
  },
});
