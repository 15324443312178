import { View, Text } from "react-native";
import i18n from "../../Lang/translations";
import GlobalParams from "../../components/Globals/GlobalParams";
import { styles } from "./LearnShapeText.styles";
import { LightLanguageEnum } from "../../components/Grids/Core/GridItem/EnumValues";

export interface ILearnShapeText {
  shape: number;
}

const LearnShapeText = (props: ILearnShapeText) => {
  return (
    <View style={styles.container}>
      <View style={styles.Left}></View>
      <View
        style={[GlobalParams.IsLargeScreen() ? styles.MainLarge : styles.Main]}
      >
        <Text style={styles.text}>
          {i18n.t("Shapes." + LightLanguageEnum.Shapes[props.shape] + ".Text")}
        </Text>
      </View>
      <View style={styles.Right}></View>
    </View>
  );
};

export default LearnShapeText;
