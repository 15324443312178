import { Platform } from "react-native";
import * as Device from "expo-device";

import GlobalParams from "../../components/Globals/GlobalParams";
import { storeData, userContext, UserIdKey } from "../../Context/userContext";
import i18n from "../../Lang/translations";
import {
  AccountErrors,
  IUser,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
} from "../types/Accountypes";
import apiClient from "./http-common";

const getMetadata = () => {
  const metadata = {
    AppVersion: GlobalParams.GetAppVersion(),
    Language: i18n.locale,
    Platform: Platform,
    Device: Device,
  };

  console.log("metadata", metadata);
  return metadata;
};

// Register
// const register = async (
//   Name: string,
//   Teacher: string,
//   Email: string,
//   Password: string
const register = async (reg: RegisterRequest) => {
  // get all other data
  const response = await apiClient.post<LoginResponse>("/account/register", {
    Name: reg.Name,
    Teacher: reg.Teacher,
    Email: reg.Email,
    Password: reg.Password,
    meta: getMetadata(),
  });
  return response.data;
};

// Login

const login = async (Email: string, Password: string) => {
  // get all other data
  const response = await apiClient.post<LoginResponse>("/account/login", {
    Email,
    Password,
    meta: getMetadata(),
  });
  return response.data;
};

// relogin
const relogin = async (UserId: string) => {
  // get all other data

  const response = await apiClient.post<LoginResponse>("/account/relogin", {
    UserId,
    meta: getMetadata(),
  });
  return response.data;
};

// delete account
const deleteAccount = async (UserId: string) => {
  // get all other data

  const response = await apiClient.post<LoginResponse>("/account/delete", {
    UserId,
    meta: getMetadata(),
  });
  return response.data;
};

const getUsers  = async () => {
  // get all user data
  const response = await apiClient.get<IUser[]>("/account/GetUsers", {});
  return response.data;
};

const setTeacher = async (_userId: string, _teacherId: string) => {
  // get all user data
  const response = await apiClient.post<string>("/account/SetTeacher", {
    userId: _userId,
    teacherId: _teacherId,
  });
  return response.data;
};

const HandleLoginResponse = (
  login: LoginResponse,
  user: userContext
): AccountErrors => {
  console.log("Login Success : ", login);

  if (login.Status === AccountErrors.OK) {
    storeData(UserIdKey, login.Id);

    user.setUserContext({
      ...user,
      Id: login.Id,
      Name: login.Name,
      // Language: user.Language,
      // ActiveGrid: {} as GridData,
      // setUserContext: user.setUserContext,
    });
  }
  GlobalParams.SetIOSLink(login.UpgradeURL);
  return login.Status;
};

// password

const AccoutService = {
  login,
  relogin,
  HandleLoginResponse,
  register,
  deleteAccount,
  getUsers,
  setTeacher,
};

export default AccoutService;
