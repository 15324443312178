import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  gridRow: {
    flex: 1,
    flexDirection: "row",
    // backgroundColor: 'skyblue',
    // height: 100
    justifyContent: "center",
    alignItems: "center",
  },

});
