import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { View, Text, Image, Button, Linking, Pressable } from "react-native";
import GlobalParams from "../components/Globals/GlobalParams";
import { globalLogout, UserContext, userContext } from "../Context/userContext";
import i18n from "../Lang/translations";
import { RootStackNavigationProp } from "../Navigation/types";
import { styles } from "./AboutScreen.styles";
import AwesomeAlert from "react-native-awesome-alerts";
import AccoutService from "../api/services/Account";

const AboutScreen = () => {
  const user = useContext<userContext>(UserContext);
  const navigation = useNavigation<RootStackNavigationProp>();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const currentYear = new Date().getFullYear();

  const deleteAccount = () => {
    AccoutService.deleteAccount(user.Id!);
    // delete account logic
    globalLogout(user);
    navigation.navigate("Home");
  };

  const deleteDialog = () => {
    return (
      <AwesomeAlert
        show={showDeleteDialog}
        showProgress={false}
        title={i18n.t("screens.about.delete")}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText={i18n.t("screens.about.delete_no")}
        confirmText={i18n.t("screens.about.delete_yes")}
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setShowDeleteDialog(false);
        }}
        onDismiss={() => {
          setShowDeleteDialog(false);
        }}
        onConfirmPressed={() => {
          setShowDeleteDialog(false);
          deleteAccount();
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.text}>{i18n.t("screens.about.appname")}</Text>
        <Text style={styles.text}>
          {i18n.t("screens.about.version")} {GlobalParams.GetAppVersion()}
        </Text>
        <Text style={styles.text}>{i18n.t("screens.about.created")}</Text>
        <Text style={styles.text}>&#169; 2017 - {currentYear.toString()}</Text>
        <Text style={styles.text}>{i18n.t("screens.about.rigths")}</Text>
      </View>
      <View style={styles.section}>
        <Pressable
          style={styles.button}
          onPress={() => {
            navigation.navigate("Home");
          }}
        >
          <Image
            style={styles.image}
            source={require("../../assets/images/ll.png")}
          />
        </Pressable>
        <Pressable
          style={styles.button}
          onPress={() => {
            Linking.openURL("mailto:lightlanguageapp@gmail.com");
          }}
        >
          <Text style={styles.text}>LightLanguageApp@gmail.com</Text>
        </Pressable>

        {deleteDialog()}

        {user.Id && (
          <Pressable
            style={styles.button}
            onPress={() => {
              setShowDeleteDialog(true);
            }}
          >
            <Text style={styles.text}>{i18n.t("screens.about.delete")}</Text>
          </Pressable>
        )}
      </View>
      <View style={styles.section}>
        <Text style={styles.text}>&#169; 2008 Light Language knowledge</Text>
        <Text style={styles.text}>Starr Fuentes</Text>
        <Text style={styles.text}>All Rigths reserved</Text>
      </View>
    </View>
  );
};

export default AboutScreen;
