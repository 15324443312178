import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  BackHandler,
  Share,
  Platform,
  TouchableOpacity,
  FlatList,
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { useQuery } from "react-query";
import GridService from "../api/services/Grids";
import GlobalParams from "../components/Globals/GlobalParams";
import { UserContext, userContext } from "../Context/userContext";
import { RootStackNavigationProp } from "../Navigation/types";
import { styles } from "./OpenScreen.styles";
import * as Clipboard from "expo-clipboard";
import { GridData } from "../api/types/GridData";
import i18n from "../Lang/translations";
import { GridType } from "../api/types/GridsTypes";

const OpenScreen = () => {
  const user = useContext<userContext>(UserContext);
  const navigation = useNavigation<RootStackNavigationProp>();
  const count = useRef(0);

  const [filterText, onChangeFilterText] = useState("");
  const [filterGridItem, setFilterGridItem] = useState<GridData[] | []>([]);

  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [selectedActionGrid, setSelectedActionGrid] = useState<GridData | null>(
    null
  );

  const {
    isLoading,
    status,
    data,
    refetch: loadGridList,
  } = useQuery<GridData[], Error>(
    ["getGridsList", user.Id], // query key
    () => GridService.List(user.Id as string), // fetching function
    {
      enabled: false,
      retry: 0,
    }
  );

  useFocusEffect(
    useCallback(() => {
      // console.log("useFocusEffect-1", count.current++);
      loadGridList();
      // the part below is generic - I just copy and paste if from
      // https://www.programcreek.com/typescript/?api=@react-navigation/native.useFocusEffect
      // more info : https://reactnavigation.org/docs/navigation-lifecycle/

      const onBackPress = (): boolean => {
        navigation.navigate("Home");
        return true;
      };
      BackHandler.addEventListener("hardwareBackPress", onBackPress);
      return (): void =>
        BackHandler.removeEventListener("hardwareBackPress", onBackPress);
    }, [navigation])
  );

  useEffect(() => {
    // console.log("filterGridItem:", filterText);
    if (data) {
      const d: GridData[] = data.filter((item) =>
        item.Name!.toLowerCase().includes(filterText.toLowerCase())
      );
      d.sort((a, b) => {
        return a.Updated! < b.Updated! ? 1 : -1;
      });
      setFilterGridItem(d);
    }
  }, [filterText, data]);

  const duplicateGrid = (d: GridData) => {
    console.log("duplicateGrid:", d, user);
    GridService.Duplicate(user.Id as string, d.Id!, d.Name!).then((r) => {
      console.log("duplicateGrid:", r);
      loadGridList();
      setSelectedActionGrid(null);
    });
  };

  const deleteGrid = (d: GridData) => {
    console.log("deleteGrid:", d);
    GridService.Delete(user.Id as string, d.Id!).then((r) => {
      console.log("deleteGrid:", r);
      loadGridList();
      setSelectedActionGrid(null);
    });
  };

  const shareGrid = (d: GridData) => {
    console.log("shareGrid:", d);
    if (Platform.OS === "web") {
      setSelectedActionGrid(d);
      setShowShareDialog(true);
    } else {
      try {
        Share.share(
          {
            message: GlobalParams.GetShareUrl(d.ExternalId!), // full link to view the grid
            url: "my url",
            title: d.Name,
          },
          { dialogTitle: d.Name, subject: d.Name }
        );
      } catch (error) {
        console.log("shareGrid:", error);
      }
    }
  };

  function openGrid(d: GridData) {
    console.log("openGrid [1]:", d.Id, d.Name);
    user.setUserContext({ ...user, ActiveGrid: d });
    navigation.navigate("Grid");
  }

  const FormatDate = (str: string) => {
    return str.split("T")[0];
  };
  const filterHeader = () => {
    return (
      <View style={styles.textLine}>
        <TouchableOpacity onPress={() => onChangeFilterText("")}>
          <Image
            source={require("../../assets/images/menu/search.png")}
            style={styles.image}
          />
        </TouchableOpacity>
        <TextInput
          style={styles.textLineInput}
          onChangeText={(filterText) => onChangeFilterText(filterText)}
          value={filterText}
        />
      </View>
    );
  };
  const emptyGridList = () => {
    return (
      <Text style={styles.textLineText}>
        Grid list empty, Create grid first
      </Text>
    );
  };

  const loadStatus = () => {
    console.log("status:", status);
    console.log("isLoading:", isLoading);
    return (
      <View>
        <Text style={styles.textLineText}>
          {i18n.t("screens.open.loading")}
        </Text>
      </View>
    );
  };

  const GetIcon = (g: GridType): any => {
    switch (g) {
      case GridType.G7:
        return require("../../assets/images/open/g7.png");
      case GridType.G7_PLUS:
        return require("../../assets/images/open/g7plus.png");
      case GridType.G49:
        return require("../../assets/images/open/g49.png");
      default:
        return require("../../assets/images/open/g7.png");
    }
  };

  const gridIconLabel = (item: GridData) => {
    return (
      // <View style={styles.iconlabel}>
      <TouchableOpacity style={styles.iconlabel} onPress={() => openGrid(item)}>
        <Image style={styles.iconLeft} source={GetIcon(item.GridType!)} />
        {gridLabel(item)}
      </TouchableOpacity>
      // </View>
    );
  };
  const gridLabel = (item: GridData) => {
    return (
      <View style={styles.label}>
        <Text style={styles.text_name}>{item.Name ? item.Name : " "}</Text>
        <Text style={styles.text_date}>{FormatDate(item.Updated!)}</Text>
      </View>
    );
  };

  const gridActionButtons = (item: GridData) => {
    return (
      <View style={styles.actionButtons}>
        <TouchableOpacity
          style={styles.iconlabel}
          onPress={() => shareGrid(item)}
        >
          <Image
            style={styles.iconRight}
            source={require("../../assets/images/menu/share.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.iconlabel}
          onPress={() => {
            setSelectedActionGrid(item);
            setShowDuplicateDialog(true);
          }}
        >
          <Image
            style={styles.iconRight}
            source={require("../../assets/images/menu/copy.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.iconlabel}
          onPress={() => {
            setSelectedActionGrid(item);
            setShowDeleteDialog(true);
          }}
        >
          <Image
            style={styles.iconRight}
            source={require("../../assets/images/menu/delete.png")}
          />
        </TouchableOpacity>
      </View>
    );
  };

  const gridLine = (item: GridData) => {
    return (
      // <View style={styles.gridLine}>
      <TouchableOpacity style={styles.gridLine} onPress={() => openGrid(item)}>

        {gridIconLabel(item)}
        {gridActionButtons(item)}
        </TouchableOpacity>
      // {/* </View> */}
    );
  };

  const gridsItems = (metadata: GridData[]) => {
    return (
      <FlatList
        data={metadata}
        renderItem={({ item }) => gridLine(item)}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  };
//#region	- dialogs
  const deleteDialog = () => {
    return (
      <AwesomeAlert
        show={showDeleteDialog}
        showProgress={false}
        title={i18n.t("screens.open.delete")}
        message={selectedActionGrid?.Name}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText={i18n.t("screens.open.delete_no")}
        confirmText={i18n.t("screens.open.delete_yes")}
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setShowDeleteDialog(false);
          setSelectedActionGrid(null);
        }}
        onDismiss={() => {
          setShowDeleteDialog(false);
          setSelectedActionGrid(null);
        }}
        onConfirmPressed={() => {
          setShowDeleteDialog(false);
          deleteGrid(selectedActionGrid as GridData);
        }}
      />
    );
  };

  const shareDialog = () => {
    return (
      <AwesomeAlert
        show={showShareDialog}
        showProgress={false}
        title={i18n.t("screens.open.share")}
        message={GlobalParams.GetShareUrl(
          selectedActionGrid?.ExternalId as string
        )}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText={i18n.t("screens.open.share_ok")}
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setShowShareDialog(false);
          setSelectedActionGrid(null);
        }}
        onDismiss={() => {
          setShowShareDialog(false);
          setSelectedActionGrid(null);
        }}
        onConfirmPressed={() => {
          setShowShareDialog(false);
          Clipboard.setStringAsync(
            GlobalParams.GetShareUrl(selectedActionGrid?.ExternalId as string)
          );
          setSelectedActionGrid(null);
        }}
      />
    );
  };

  const duplicateDialog = () => {
    return (
      <AwesomeAlert
        show={showDuplicateDialog}
        showProgress={false}
        title={i18n.t("screens.open.duplicate")}
        message={selectedActionGrid?.Name}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText={i18n.t("screens.open.duplicate_no")}
        confirmText={i18n.t("screens.open.duplicate_yes")}
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setShowDuplicateDialog(false);
          setSelectedActionGrid(null);
        }}
        onDismiss={() => {
          setShowDuplicateDialog(false);
          setSelectedActionGrid(null);
        }}
        onConfirmPressed={() => {
          setShowDuplicateDialog(false);
          duplicateGrid(selectedActionGrid as GridData);
        }}
      />
    );
  };
  //#endregion

  return (
    <View style={styles.container}>
      {filterHeader()}
      {isLoading && loadStatus()}
      {data && data.length > 0
        ? gridsItems(filterGridItem)
        : !isLoading && emptyGridList()}
      {deleteDialog()}
      {shareDialog()}
      {duplicateDialog()}
    </View>
  );
};

export default OpenScreen;
