import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  WordList: {
    flex: 1,
  },
  ShapeColor: {
    flex: 1,
  },
  ListItem: {
    backgroundColor: "white",
    padding: 16,
    borderColor: "lightgray",
    borderBottomWidth: 1,
  },
  Text: {
    color: "black",
    fontSize: 16,
  },
  selectedItem: {
    backgroundColor: "lightblue",
  },
});
