import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // backgroundColor: "whitesmoke",
    flexDirection: "row",
  },
  // -----------
  Left: {
    flex: 1,
  },
  Main: {
    flex: 12,
    // borderColor: "black",
    // borderWidth: 2,
  },
  MainLarge: {
    flex: 3,
    // borderColor: "black",
    // borderWidth: 2,
  },
  Right: {
    flex: 1,
  },
  // -----------

  text: {
    fontSize: 16,
  },
});
