import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { Image, TouchableOpacity } from "react-native";
import { UserContext, userContext } from "../Context/userContext";
import { styles } from "./LanguageSelection.styles";
import { RootStackNavigationProp } from "./types";
import Languages from "../Lang/Languages";

// icons from : https://www.shutterstock.com/image-vector/web-language-icon-collection-600w-43740322.jpg

const LanguageSelection = () => {
  const navigation = useNavigation<RootStackNavigationProp>();
  const user = useContext<userContext>(UserContext);

  const SelectLanguage = () => {
    navigation.navigate("Language");
  };

  return (
    // <View>
    <TouchableOpacity
      style={styles.login_register}
      onPress={() => SelectLanguage()}
    >
      <Image
        style={styles.image}
        {...Languages.GetLanguage(user.Language).imageSmall}
      />
    </TouchableOpacity>
    // </View>
  );
};

export default LanguageSelection;
