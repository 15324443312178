import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  view: {
    // backgroundColor: 'skyblue',
    // borderColor: 'black',
    // borderWidth: 1,
    // width: '100%',
    // height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    width: 74,
    height: 74,
  },
  image: {
    // backgroundColor: 'yellow',
    // borderColor: 'blue',
    // borderWidth: 1,
    width: 64,
    height: 64,
  },
});
