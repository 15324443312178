import Languages, { Language } from "../Lang/Languages";

import { createContext } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GridData } from "../api/types/GridData";

export const UserContext = createContext<userContext>({} as userContext);
export const UserIdKey = "UserId";

export type userContext = {
  Id: string | null;
  Name: string | null;
  Language: Language;
  ActiveGrid: GridData;
  ViewOnlyByExternalId: string | null;
  setUserContext(newUser: userContext): void;
};

export const storeData = async (key: string, value: string) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    // saving error
  }
};

export const getData = async (key: string) => {
  try {
    return /*const jsonValue =*/ await AsyncStorage.getItem(key);
    // return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

export const removeItem = async (key: string) => {
  try {
    return /*const jsonValue =*/ await AsyncStorage.removeItem(key);
    // return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

export const globalLogout = (user: userContext) => {

  // setUserContext({Id:null,Name:null,Language:null,setUserContext:setUserContext});\
  user.setUserContext({ ...user, Id: null, Name: null });
  removeItem(UserIdKey);
};
