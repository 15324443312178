import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    // alignItems: "stretch",
    // alignContent: "stretch",
    flexDirection: "column",
  },
  header: {
    // flex: 1,
    alignSelf: "stretch",
    // justifyContent: "center",
    // backgroundColor: "lightblue",
    marginBottom: 5,
  },
  main: {
    flex: 1,
    // backgroundColor: "yellow",
    // alignItems: "stretch",
    // alignContent: "stretch",
    borderColor: "black",
    borderwidth: 5,
    
  },
  textLine: {
    // flex: 1,
    // marginTop: 5,
    // marginBottom: 20,
    // marginLeft:10,
    // marginRight:10,
    flexDirection: "row",

    alignItems: "stretch",
    alignContent: "stretch",
    justifyContent: "center",

    // backgroundColor: "lightgreen",
  },
  textLineText: {
    // flex: 1,
    alignSelf:  "flex-start",
    // backgroundColor: "lightbrown",
  },
  textLineInput: {
    flex: 1,
    fontSize: 20,
    marginHorizontal:50,
    marginTop:8,
    borderWidth: 1,
    borderColor: "lightgray",

  },
});
