import { ScrollView } from "react-native";
import { styles } from "./HomeScreen.styles";

import LL_Header from "./HeaderComponents/LL_Header";
import DailyGrid from "./HeaderComponents/DailyGrid";
import StoresLogo from "./HeaderComponents/StoresLogo";
import NewOpenControl from "./HeaderComponents/NewOpenControl";
import LoginRegisterControl from "./HeaderComponents/LoginRegisterControl";

const HomeScreen = () => {
  return (
    <ScrollView style={styles.container}>
      <LL_Header />
      <NewOpenControl />
      <DailyGrid />
      <StoresLogo />

    </ScrollView>
  );
};

export default HomeScreen;
