import { useNavigation } from "@react-navigation/native";
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  Pressable,
  Linking,
  FlatList,
} from "react-native";
import { RootStackNavigationProp } from "../Navigation/types";
import { styles } from "./LanguageScreen.styles";

import "react-native-get-random-values";
import { useContext, useState } from "react";
import { UserContext, userContext } from "../Context/userContext";
import Languages, { ILangItem } from "../Lang/Languages";

const LanguageScreen = () => {
  const user = useContext<userContext>(UserContext);

  const navigation = useNavigation<RootStackNavigationProp>();
  const [showOther, setShowOther] = useState(false);

  const nav = (item: ILangItem): void => {
    // console.log("nav: ", item);
    if (item.lang !== Languages.Language.Other) {
      Languages.SetDefaultLanguage(user, item.lang);
      navigation.goBack(); // navigate("Home");
      setShowOther(false);
    } else {
      setShowOther(true);
    }
  };

  function renderItem(item: ILangItem) {
    return (
      <TouchableOpacity
        style={styles.language_item}
        key={item.name.toString()}
        onPress={() => nav(item)}
      >
        <Image style={styles.image} {...item.image} />
        <Text style={styles.text}>{item.name}</Text>
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Select Language</Text>
      <FlatList 
        data={Languages.LangItems}
        renderItem={({ item }) => renderItem(item)}
      />
      {showOther && (
        <Pressable
          style={styles.button}
          onPress={() => {
            Linking.openURL("mailto:lightlanguageapp@gmail.com");
          }}
        >
          <Text style={styles.text}>Looking for more languages</Text>
          <Text style={styles.text}>send us an email:</Text>
          <Text style={styles.text}>LightLanguageApp@gmail.com</Text>
        </Pressable>
      )}
    </View>
  );
};

export default LanguageScreen;
