import { View, Text, Image, FlatList, Platform } from "react-native";
import {
  BuildWordGridItem,
  PredefineShapes,
  WordGridItem,
} from "../../components/Grids/Core/PredefineShapes/PredefineShapes";
import { styles } from "./LearnSetStage.styles";
import { LearnMode } from "../LearnMode";
import i18n from "../../Lang/translations";
import { Grid49Enum } from "../../components/Grids/Grid49/SetStageEnum";
import { useState } from "react";
import GlobalParams from "../../components/Globals/GlobalParams";

const sets_path = require("../../../assets/images/learn/sets.png");
const stages_path = require("../../../assets/images/learn/stages.png");

interface ILearnSetStageProps {
  type: LearnMode; // Sets or Stages
}
var i = 0;
const LearnSetStage = (props: ILearnSetStageProps) => {
  console.log("LearnSetStage: ", props);

  const imagePath = props.type == LearnMode.Sets ? sets_path : stages_path;
  const type = props.type == LearnMode.Sets ? "Sets" : "Stages";
  const Title = i18n.t(type + "._.Label");
  const Desc = i18n.t(type + "._.Desc");

  const [selectedSet, setSelectedSet] = useState<string>("");
  const [selectedStage, setSelectedStage] = useState<string>("");

  const shapes = BuildWordGridItem(
    props.type == LearnMode.Sets ? Grid49Enum.Sets : Grid49Enum.Stages,
    type
  );

  const header = () => {
    return (
      <View style={styles.header}>
        <Text style={styles.title}>{Title}</Text>

        <Image source={imagePath} style={styles.image} />
        <Text style={styles.desc}>{Desc}</Text>

        <Text style={styles.title}>{GetLabelDesc("Label")}</Text>
        <Text style={styles.desc}>{GetLabelDesc("Desc")}</Text>
      </View>
    );
  };

  const GetLabelDesc = (info: string): string => {
    var key = selectedStage;
    if (props.type == LearnMode.Sets) {
      key = selectedSet;
    }
    if (!key) return "";

    return i18n.t(type + "." + key + "." + info);
  };

  const StageSetSelected = (item: WordGridItem | null, final: boolean) => {
    console.log("Stage Set Selected", props.type, item, final);
    var name = "";
    if (item) {
      name = item.Name;
    }
    if (props.type == LearnMode.Sets) {
      setSelectedSet(name);
    } else {
      setSelectedStage(name);
    }
  };
  return (
    <View style={styles.container}>
    {Platform.OS === "web" && GlobalParams.IsLargeScreen() && (
      <View style={styles.leftrigth}></View>
    )}

    <View style={styles.leftrigth}>
      <FlatList
        ListHeaderComponent={header()}
        data={[]}
        renderItem={({ item }) => <View></View>}
        ListFooterComponent={
          <PredefineShapes
            wordlist={shapes}
            selectedWord={
              props.type == LearnMode.Sets ? selectedSet : selectedStage
            }
            onClick={(item, final) => StageSetSelected(item, final)}
            showModal={false}
          />
        }
      />
    </View>
      {Platform.OS === "web" && GlobalParams.IsLargeScreen() && (
        <View style={styles.leftrigth}></View>
      )}
    </View>
  );
};

export default LearnSetStage;
