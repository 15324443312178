import i18n from "../../../../Lang/translations";
import { LightLanguageEnum } from "./EnumValues";

/*
shape and color holds the Id of the item in the color and shape array
*/
// const DisplaySeperator = " - ";
const DisplaySeperator = "\n";

export interface GridItem {
  Shape: number;
  Color: number;
}

export const GridItem_Undefined: number = -1;

export function GetGridItemImagePath(gridItem: GridItem): string {
  let shape_color = "";
  let shape = null;
  let color = null;

  // convert the enum value to the array name
  if (gridItem.Shape !== GridItem_Undefined) {
    shape = LightLanguageEnum.Shapes[gridItem.Shape];
  }

  if (gridItem.Color !== GridItem_Undefined) {
    color = LightLanguageEnum.Colors[gridItem.Color];
  }

  if (shape !== null && color !== null) {
    shape_color += shape + "_" + color;
  } else if (shape != null) {
    shape_color += shape;
  } else if (color != null) {
    shape_color += color;
  } else {
    // default - show empty rect
    shape_color +=
      LightLanguageEnum.Shapes[0] + "_" + LightLanguageEnum.Colors[0];
  }

  return shape_color.toLowerCase(); //+ '.png';
}

export function GetGridItemText(gridItem: GridItem): string {
  if (
    gridItem.Shape !== GridItem_Undefined &&
    gridItem.Color !== GridItem_Undefined
  ) {
    return (
      i18n.t("Shapes." + LightLanguageEnum.Shapes[gridItem.Shape] + ".Label") +
      DisplaySeperator +
      i18n.t("Colors." + LightLanguageEnum.Colors[gridItem.Color] + ".Label")
    );
  }

  if (gridItem.Shape !== GridItem_Undefined) {
    return i18n.t(
      "Shapes." + LightLanguageEnum.Shapes[gridItem.Shape] + ".Label"
    );
  }

  if (gridItem.Color !== GridItem_Undefined) {
    return i18n.t(
      "Colors." + LightLanguageEnum.Colors[gridItem.Color] + ".Label"
    );
  }

  return ""; // default - show empty text
}

export function GetGridItemDescription(gridItem: GridItem): string {
  if (
    gridItem.Shape !== GridItem_Undefined &&
    gridItem.Color !== GridItem_Undefined
  ) {
    return i18n.t(
      "ColorShape." +
        LightLanguageEnum.Colors[gridItem.Color] +
        "." +
        LightLanguageEnum.Shapes[gridItem.Shape]
    );
  }

  if (gridItem.Shape !== GridItem_Undefined) {
    return i18n.t(
      "Shapes." + LightLanguageEnum.Shapes[gridItem.Shape] + ".Desc"
    );
  }

  if (gridItem.Color !== GridItem_Undefined) {
    return i18n.t(
      "Colors." + LightLanguageEnum.Colors[gridItem.Color] + ".Desc"
    );
  }

  return ""; // default - show empty text
}
