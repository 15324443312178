import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    // marginHorizontal: 20,
    paddingHorizontal: 5,
    // flex: 1,
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "space-between",
    // alignItems: "space-between",
    // backgroundColor: "lightblue",
    backgroundColor: "linen",//"lightgreen",blanchedalmond linen
  },
  text_container: {
    // flex: 1,
    alignItems: "flex-start",
  },

  text_h1: {
    // flex: 1,
    alignSelf: "center", // "flex-start",
    fontSize: 24,
    fontWeight: "bold",
    // backgroundColor: "lightbrown",
    marginTop: 5,
  },
  text_h2: {
    // flex: 1,
    alignSelf: "center", // "flex-start",
    fontSize: 16,
    // fontWeight: "bold",
    // backgroundColor: "lightbrown",
    // margin: 10,
  },
  image: {
    width: 48,
    height: 48,
    // marginTop: 20,
    // padding:5,
    margin: 10,
  },
  imageIcon: {
    width: 24,
    height: 24,
    margin: 6,
  },
});
