import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  ShapeColorContainer: {
    flex: 1,
    alignContent: "stretch",
    justifyContent: "space-between",

    // backgroundColor: 'red',
    // borderColor: "black",
    // borderWidth: 2,
    
    margin: 2,
  },
  v: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: 'green',
  },
  description: {
    flex: 1,
    paddingLeft: 20,
    // backgroundColor: 'yellow',
  },
});
