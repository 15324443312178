import React, { useContext, useState } from "react";
import { UserContext, userContext } from "../../Context/userContext";
import {
  GridItem,
  GridItem_Undefined,
} from "../../components/Grids/Core/GridItem/GridItem";
import { LightLanguageEnum } from "../../components/Grids/Core/GridItem/EnumValues";
import LearnColorsShapes from "./LearnColorsShapes";

export interface ILearnColors {
  //onClick: (gridItem: GridItem) => void;
  Color: number;
}

const LearnColors = (props: ILearnColors) => {
  const user = useContext<userContext>(UserContext);

  let maxShape = LightLanguageEnum.Shapes.length - 1;

  let ShapeData: GridItem[] = LightLanguageEnum.Shapes.slice(1, maxShape).map(
    (x: string, i: number) => {
      return { Shape: i + 1, Color: props.Color }; // GridItem_Undefined };
    }
  );

  return (
    <LearnColorsShapes data={ShapeData} onClick={() => {}} readonly={true} />
  );
};

export default LearnColors;
