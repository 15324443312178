import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  section: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 144,
    height: 144,
  },
  text: {
    fontSize: 20,
  },
  button: {
    marginTop: 35,
  },
});
