import React, { useState } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import { WordName } from "./PredefineShapes";
import { styles } from "./WordsList.styles";

export interface WordsListProps {
  wordlist: WordName[];
  selectedWord: string;
  onClick: (selectedWord: string) => void;
}

export const WordsList = (props: WordsListProps) => {
  console.log("WordsList: " , props);

  const renderItem = (item: WordName) => {
    return (
      <TouchableOpacity
        key={item.Name}
        style={[
          styles.ListItem,
          props.selectedWord === item.Name ? styles.selectedItem : {},
        ]}
        onPress={() => {
          props.onClick(item.Name);
        }}
      >
        <Text style={styles.Text}>{item.DisplayName}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.WordList}>
      <FlatList
        data={props.wordlist.sort((a, b) =>
          a.DisplayName.localeCompare(b.DisplayName)
        )}
        renderItem={({ item }) => renderItem(item)}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
};

export default WordsList;
