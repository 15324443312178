import { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  Button,
} from "react-native";
import { globalLogout, UserContext, userContext } from "../Context/userContext";
import { styles } from "./LoginScreen.styles";

const LogoutScreen = () => {
  const user = useContext<userContext>(UserContext);

//   const [loginEnabled, setLoginEnabled] = useState<boolean>(false);

  const DoLogout = () => {
    console.log("Logout screen - DoLogout: ");
    globalLogout(user);
  };

  return (
    <View style={styles.container}>
      <Text>Id: {user.Id}</Text>
      <Text>Name: {user.Name}</Text>
      <Button 
        onPress={DoLogout}
        title="Logout"
        color="lightgreen"
        accessibilityLabel="Logout"
        // disabled={!loginEnabled}
/>
      {/* <View style={styles.footer}>
        <TouchableOpacity onPress={() => DoLogout()}>
          <Text style={styles.textLineText}>Logout </Text>
        </TouchableOpacity>
      </View> */}
    </View>
  );
};

export default LogoutScreen;
