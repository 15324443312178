export const Grid49Enum = {
    Sets: [
        ["Circulatory", "7:1,8:7,9:1,7:5,9:5,8:7,7:1"],
        ["Digestive", "2:1,1:4,10:4,4:7,8:4,11:2,12:7"],
        ["Gland", "3:4,2:1,8:4,8:7,10:4,10:7,1:4"],
        ["Lymph", "4:7,1:4,8:7,2:4,2:7,8:3,4:7"],
        ["Muscular", "7:1,2:3,7:1,9:7,7:1,8:5,7:1"],
        ["Nervous", "7:3,8:7,12:1,8:7,12:7,8:3,12:3"],
        ["Reproductive", "2:1,10:4,2:7,12:4,2:6,7:1,4:4"],
        ["Respiratory", "8:1,10:5,1:4,2:1,1:4,10:1,8:5"],
        ["Skeletal", "4:3,7:3,7:7,8:3,7:7,7:3,4:7"],
        ["Urinary", "7:6,10:3,9:4,8:4,2:15,13:3,4:7"],
        ["Integumentary", "3:7,9:5,3:7,1:1,3:7,4:2,3:13"],
        ["Immune system", "12:1,7:3,3:4,14:13,2:11,7:5,12:3"]  // set for hila


    ],

    Stages: [
        ["Attitudinal", "2:5,3:3,1:2,3:5,1:2,3:1,2:5"],
        ["Building", "7:7,8:4,3:5,10:4,9:7,2:3,9:2"],
        ["City", "2:4,3:3,8:4,12:7,10:4,9:5,3:2"],
        ["Competitive", "2:2,3:2,10:2,9:4,10:5,3:2,2:2"],
        ["Environmental", "1:6,10:5,8:4,9:4,8:1,10:2,1:2"],
        ["Genetic", "12:7,12:1,3:4,8:7,3:2,12:1,12:7"],
        ["Pattern", "2:6,7:2,7:5,1:6,1:5,7:2,2:6"],
        ["Reactive", "7:6,9:1,7:5,3:2,7:2,3:1,2:1"],
        ["Social", "3:6,7:2,9:4,8:2,9:3,7:5,3:2"],
        ["Integrity", "7:7,5:1,9:5,9:11,9:7,6:1,7:5"],
        ["Alignment", "13:13,13:2,8:6,12:3,8:6,13:2,13:4"],
        ["Holographic", "2:5,10:2,9:7,7:12,9:1,12:15,2:1"]  // stage for hila

    ]
};