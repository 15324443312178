import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { View, Image, Text, Pressable } from "react-native";
import { UserContext, userContext } from "../../Context/userContext";
import i18n from "../../Lang/translations";
import { RootStackNavigationProp } from "../../Navigation/types";
import { styles } from "./LL_Header.styles";

const LL_Header = () => {
  const user = useContext<userContext>(UserContext);
  const navigation = useNavigation<RootStackNavigationProp>();

  const clickableImage = () => {
    return (
      <Pressable
        onPress={() => {
          navigation.navigate("About");
        }}
      >
        <Image
          style={styles.image}
          source={require("../../../assets/images/ll.png")}
        />
      </Pressable>
    );
  };

  return (
    <View style={styles.header}>
      {clickableImage()}
      <View style={styles.text_container}>
        <Text style={styles.text_h1}>
          {i18n.t("screens.home.header.title")}
        </Text>
        <Text style={styles.text_h2}>
          {i18n.t("screens.home.header.sub_title")}
        </Text>
      </View>
      {clickableImage()}
    </View>
  );
};

export default LL_Header;
