import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  NewGridScreen: {
    flex: 1,
    flexDirection: "column",
    // backgroundColor: 'lime',
    // borderColor: 'black',
    // borderWidth: 2,
    // width: "60%",
    // height: "60%",
    // justifyContent: "flex-start",
    justifyContent: "space-evenly",// "center", //space-between
    alignItems: "center",
  },
  image: {
    width: 100,
    height: 100,
  },
  text: {
    textAlign: "center",
    fontSize: 20,
    marginTop: 20,
  },
});
